import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  messagesCount: [],
}

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    getMessageCount: (state, { payload }) => {
      state.messagesCount = payload;
    }
  }
});

export const { getMessageCount } = notificationSlice.actions;
export default notificationSlice.reducer;

