/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react'
import Spinner from '../../shared/spinner';

function Table({
  title,
  columns,
  data,
  header,
  count,
  defaultPage,
  page,
  onChange,
  totalPages,
  loader
}) {
 
  return (
    <section className='w-full flex flex-col justify-start antialiased  text-gray-600  p-4'>
      <div className='h-full'>
        <div className='w-full  mx-auto bg-white shadow-sm rounded-sm border border-gray-200'>
          <header className='w-full oveflowx-auto px-5 py-4 border-b border-gray-100'>
            {header()}
          </header>
          <div className='p-3'>
            
            <div className='overflow-x-auto'>
            {loader ? 
              (<div className='w-full flex ' style={{"textAlign":'center',justifyContent:'center'}}>
                <Spinner />
              </div>):
              (<table className='table-auto w-full'>
                <thead className='text-xs font-semibold uppercase text-everglade-700 bg-everglade-200'>
                  <tr>
                    {columns?.map((header, index) => (
                      <th className='p-2 whitespace-nowrap' key={index}>
                        <div className='font-semibold text-left'>
                          {header.header}
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className='text-sm divide-y divide-gray-100'>
                  {data?.map((row, index) => (
                    <tr key={index}>
                      {columns?.map((column, colIndex) => (
                        <td
                          className='p-2 capitalize'
                          key={colIndex}
                        >
                          <div className='flex items-center'>
                            <div className='font-medium text-gray-800'>
                              {typeof column.cell === 'function'
                                ? column.cell(row)
                                : row[column.key]}
                            </div>
                          </div>
                        </td>
                      ))}
                    </tr>
                  ))}
                  {data?.length === 0 && (
                    <tr className='p-4'>
                      <td>No Record found</td>
                    </tr>
                  )}
                </tbody>
              </table>)}
            </div>
            {/* plan to refactor pagination and use this */}
            {/* <RefactorPagination count={count} defaultPage={defaultPage} page={page} onChange={onChange} totalPages={totalPages}/> */}
          </div>
        </div>
      </div>
    </section>
  )
}
export default Table
