/* eslint-disable jsx-a11y/no-redundant-roles */
import useSendbirdStateContext from "@sendbird/uikit-react/useSendbirdStateContext";
import { useEffect, useState } from "react";
import Spinner from "../spinner";
import Avatar from "./avatar";
import { toastError } from "../../../utils/helpers/methods";
import { backendURL } from "../../../config/api";

const ChatWithDPC = ({loading,activeTab,data,handleSelected}) => {

  const [available, setAvailable] = useState([]);
  const [nonAvailable, setNoAvailable] = useState([]);
  const store = useSendbirdStateContext()
  var sdk = store?.stores.sdkStore.sdk
  
  let url = `${backendURL}/img/practitioner`

  useEffect(() => {
      const available = data?.data?.practitioners_internal?.filter(item=>item.doctor_login!==null && item.doctor_login.sendbird_user_id !== null);
      const nonAvailable = data?.data?.practitioners_internal?.filter(item=>item.doctor_login===null);
      setAvailable(available);
      setNoAvailable(nonAvailable);
//eslint-disable-next-line
  }, []);

  const goToChat = async(item) => {
    const user = item.doctor_login.sendbird_user_id != undefined && item.doctor_login.sendbird_user_id != null;
    if (user) {
      handleSelected(item);
    } else {
      toastError("Not able to chat at this moment.");
    }
  }

  return (

        <div className="w-full mx-auto mt-4 space-y-4 px-10">
        
        <div className='w-full bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700'>
          <div className="flow-root">
          <ul role="list" className="divide-y divide-gray-200 dark:divide-gray-700">
          {loading && <section className="w-full flex justify-center"><Spinner /></section>}
          {activeTab === 1  && !loading && available?.map((item, index) => {
            return (
              <section className="flex space-x-4 p-2 flex-row items-center" key={index} onClick={() => goToChat(item)}>
                <Avatar fname={item?.name} image={item.profile_image?`${url}/${item.profile_image}`:''} />
                <p className="capitalize cursor-pointer">{item?.name} </p>
              </section>
            );
          })}
          </ul>
          <ul role="list" className="divide-y divide-gray-200 dark:divide-gray-700">
          {activeTab === 2  && !loading && nonAvailable?.map((item, index) => {
            return (
              <section className="flex space-x-4 p-2 flex-row items-center" key={index}>
                <Avatar fname={item?.name} image={item.profile_image?`${url}/${item.profile_image}`:''} />
                <p className="capitalize cursor-pointer">{item?.name} </p>
              </section>
            );
          })}
          </ul>
          
          </div>
        </div>

      
          {activeTab===1 && !loading && available.length===0 && <span className="w-full text-center ">No list found</span>}
          {activeTab===2 && !loading && nonAvailable.length===0 && <span className="w-full text-center ">No list found</span>}

        </div>
  );
};
export default ChatWithDPC; 