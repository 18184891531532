import { Icon } from '@iconify/react'
import React from 'react'

const Navigator = ({ icon, item, pathname}) => { 
  pathname=pathname=='/'?'/dashboard':pathname
  let selected = pathname === item.path || (pathname.includes("/patientDetails") && item.path === "/patientList");

  return (
    <div className={`w-[100%] p-5 flex flex-row justify-between items-center cursor-pointer group ${selected ? "bg-everglade-300" : ''}`} >
        <div className='flex flex-row gap-[20px] items-center'>
            <div className={`w-[35px] h-[35px] rounded-md flex items-center ${selected ? "bg-everglade-800" : "bg-white"}`}>
                <Icon icon={icon} fontSize={19} className={`ml-[7px] ${selected?'text-white':'text-everglade-500'}`} />
            </div>
            <h1 className={`text-[18px] font-semibold group-hover:text-everglade-800 ${selected ? "text-everglade-800" : "text-white"}`}>{item.text}</h1>
        </div>
        { selected && <Icon icon='material-symbols:arrow-right-rounded'className='text-everglade-800' fontSize={19} /> }
    </div>
  )
}

export default Navigator
