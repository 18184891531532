import useSendbirdStateContext from "@sendbird/uikit-react/useSendbirdStateContext";
import { useState, useEffect } from 'react';
import ChanneLItemPreview from '../chatItemPreview';
import Spinner from '../../spinner';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PrevChanneLItemPreview from "./PrevchatItemPreview";

const PrevChatPreview = ({data}) => {
  const store = useSendbirdStateContext();
  var sdk = store?.stores.sdkStore.sdk;
  const user = store?.stores.userStore.user;
  const [channelList, setChannelList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [channelInstance, setChannelInstance] = useState('');

  const userId = useSelector(state => state.auth);
  const [type, setType] = useState('');
   const navigate=useNavigate();
  const fetchChannels = async () => {
    let sendbird = sdk;
    var query = sendbird.groupChannel.createPublicGroupChannelListQuery({
      channelUrlsFilter: data?.map((x) => x.channel_url),
    });
    if (query.hasNext) {

      const fetchedChannels = await query.next()
      if (fetchedChannels.length > 0) {
        setChannelList(fetchedChannels);
        setLoading(false);
      }
      else {
        setLoading(false)
      }
    }
    else {
      setLoading(false)

    }

  }
  useEffect(() => {
    if (sdk.updateCurrentUserInfo) {
      if (user.nickname === "") {
        const params = {
          nickname: userId?.userInfo.name
        };
        sdk.updateCurrentUserInfo(params).then(resp => {
          window.location.reload();
        });
      }
    }

  }, [sdk]);

  useEffect(() => {
    if (!sdk || !sdk.openChannel) {
      return;
    }
    fetchChannels();
  }, [sdk]);

  const handleChatView = (item) => {
    navigate('/pro-chat',{state:{channel:item}})
  }
  return (
    <>
      <div className="sendbird-app__channellist-wrap w-full flex flex-col" style={{ width: '100%' }}>
        <section className='w-full h-14 p-8 flex text-care-dark-red justify-start items-center font-semibold text-xl border-b'>Chat</section>
        <section className='w-full flex-1 h-20  p-2'>
          {loading && <section className='flex-1 w-full h-1/2 flex justify-center items-center'><Spinner /></section>}
          {!channelList.length > 0 && !loading && <p className='flex-1 w-full h-1/2 flex justify-center items-center text-base'>No Conversation</p>}
          {channelList.length > 0 && channelList.map((item, index) => (
            <PrevChanneLItemPreview item={item} key={index} onClick={handleChatView} currentUser={user} practiceName={data[0]?.practice?.name}/>
          ))}
        </section>
        </div>
    </>
  );
}
export default PrevChatPreview;