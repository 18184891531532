import { useEffect, useState } from 'react'
import { FaClock } from 'react-icons/fa'
import { VscCallOutgoing } from 'react-icons/vsc'
import { useSelector } from 'react-redux'
import { SENDBIRD_INFO } from '../../../utils/helpers/constant'

const ChatHistory = () => {
  const [history, setHistory] = useState(null)

  const userId = useSelector((state) => state.auth)
  const sendbirdId = userId?.userInfo?.doctor_login?.sendbird_user_id

  const fetchSendbirdChatHistory = async () => {
    const result = await (
      await fetch(
        `https://api-${SENDBIRD_INFO.appId}.calls.sendbird.com/v1/direct_calls`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Api-Token': SENDBIRD_INFO.sendBirdToken,
          },
        }
      )
    ).json()
    setHistory(result)
  }

  useEffect(() => {
    ;(async () => {
      await fetchSendbirdChatHistory()
    })()
  }, [])
  const filteredHistory = history?.calls?.filter(
    (call) => call.started_by === sendbirdId
  )

  const getDurationInSeconds = (durationTime) => {
    if (durationTime === 0) return 'N/A'
    const durationSeconds = Math.round(durationTime / 1000)

    // const hours = Math.floor(durationSeconds / 3600)
    // const minutes = Math.floor((durationSeconds % 3600) / 60)
    // const seconds = durationSeconds % 60
   
    var hours = Math.round(durationTime/ (1000 * 60 * 60) % 24);
    var minutes = Math.round((durationTime / (1000 * 60)) % 60);
    var seconds = Math.round((durationTime/ 1000) % 60);
    const duration = `${hours > 0 ? `${hours}h ` : ''}${
      minutes > 0 ? `${minutes}m ` : ''
     }${seconds > 0 ? `${seconds}s` : ''}`
    return duration
  }

  return (
    <div className='p-6'>
      <h2 className='text-2xl font-bold mb-6'>Chat History</h2>
      <div>
        {filteredHistory && filteredHistory.length > 0 ? (
          filteredHistory.map((call, index) => (
            <div
              key={index}
              className='flex flex-col border border-gray-300 p-4 mb-4 rounded-lg bg-gray-100'
            >
              <div className='flex items-center gap-5'>
                {call.participants
                  .filter((userObj) => userObj.user_id !== sendbirdId)
                  .map((participant, idx) => (
                    <div key={idx} className='flex flex-col items-center'>
                      <img
                        src={participant.profile_url}
                        alt={participant.nickname}
                        className='w-10 h-10 rounded-full'
                      />
                      <p className='font-semibold'>{participant.nickname}</p>
                    </div>
                  ))}

                <div className='flex flex-col'>
                  <div className='flex items-center mb-2'>
                    <p>
                      Status :{' '}
                      <span
                        className={
                          call.end_result === 'completed'
                            ? 'text-everglade-500'
                            : 'text-red-600'
                        }
                      >
                        {call.end_result}
                      </span>{' '}
                    </p>
                  </div>
                  <div className='flex items-center gap-2'>
                    {call.duration>0 && <VscCallOutgoing
                      className={
                        call.end_result === 'completed'
                          ? 'text-everglade-500'
                          : 'text-red-600'
                      }
                    />}
                    {call.duration>0 && <div className='mr-10 flex flex-row gap-2 items-center'>
                      <p>Duration :</p>
                      <p>{getDurationInSeconds(call.duration)}</p>
                    </div>
                    }                   
                    <FaClock className=' text-gray-600' />
                    <p className='text-sm text-gray-500'>
                      {new Date(call.started_at).toLocaleString()}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p className='text-gray-500 text-center'>No call history available</p>
        )}
      </div>
    </div>
  )
}

export default ChatHistory
