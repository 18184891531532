import { useEffect, useState } from "react";
import {MdKeyboardArrowLeft} from "react-icons/md";
const Pagination = ({ count, defaultPage, page, onChange, totalPages ,totalCount}) => {
    const [pages, setPages] = useState([])
    const [active, setActive] = useState();
    const [startRange, setStartRange] = useState(0);
    const [endRange, setEndRange] = useState(count);
    useEffect(() => {
        let number = [];
        for (let i = 1; i <= totalPages; i++) {
            number.push(i);
        }
        setPages(number);
        setActive(defaultPage > 0 ? defaultPage : 1);
    }, []);

    useEffect(() => {
        page > 0 && setActive(page);
    }, [page])

    const handleActivePage = (page) => {
        setActive(page);
        onChange && onChange(page);
    }
    const handleNextPage = () => {
        if (pages.length > active) {
            handleActivePage(active + 1);
        }
        if (pages.length > endRange) {
            setEndRange(endRange + 1)
            setStartRange(startRange + 1)
        }
    }
    const handlePrevPage = () => {
        if (active > 0) {
            handleActivePage(active - 1);
        }
        if (startRange > 0) {
            setEndRange(endRange - 1)
            setStartRange(startRange - 1)
        }
    }
    return (
        <nav aria-label="Page navigation example">
            {totalCount && <span className=" text-gray-500">Total Count of {totalCount}{" "}</span>}
            <ul className="inline-flex -space-x-px">
                <li onClick={handlePrevPage}>
                <a href="#" className="px-3 py-2 ml-0 leading-tight text-gray-500 hover:bg-everglade-600 hover:text-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700">Previous</a>
                </li>
                {startRange > 0 && <li>
                    <a href="#" className="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 "><span className="rotate-180">{"<"}</span></a>
                </li>}
                {pages?.slice(startRange, endRange)?.map((page, index) => (
                    <li key={index} onClick={() => handleActivePage(page)} >
                        <a href="#" className={`px-3 py-2 leading-tight   border border-gray-300 ${active === page ? 'bg-everglade-600 text-everglade-600 hover:bg-everglade-600 hover:text-white text-white' : ' hover:bg-everglade-600 hover:text-white text-black '}`}>{page}</a>
                    </li>
                ))}
                {pages.length > endRange && <li>
                    <a href="#" className="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300">{">"}</a>
                </li>}
                <li>
                    <a href="#" className="px-3 py-2  leading-tight  text-gray-500  border border-gray-300 rounded-r-lg hover:bg-everglade-600 hover:text-white" onClick={handleNextPage}>Next</a>
                </li>
            </ul>
        </nav>

    )
}
export default Pagination;