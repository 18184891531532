import { Icon } from '@iconify/react';
import React, { useEffect, useState } from 'react';
import 'react-phone-number-input/style.css';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Img from '../images/icon.png';
import { forgetPassword } from '../redux/actions/authActions';
import { toastError } from '../utils/helpers/methods'


const SignUp = () => {
    const { loading, error, success , successMessage,status} = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    const [data, setData] = useState({
        fullnames: '',
        phone: '',
        email: '',
        password: '',
        isPasswordVisible: false,
        isRequiredOn: true,
        isEmailValid: true,
        isError: false,
        isInvaliedemail:false,
        islinksend:false,
    });

    useEffect(() => {
     //   if(error) 
       // setData({ ...data, isError: true }); 
       
    }, [loading, error, success])

    const handleSignUp = (e) => {
        e.preventDefault();
        if(
            // data.fullnames.length === 0 ||
             data.email.length === 0 
           // || data.password.length === 0 ||
             // typeof phone === 'undefined'
               ){
            setData({ ...data, isRequiredOn: false });
            return false;
        }
        let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!regEmail.test(data.email)){
            return setData({ ...data, isEmailValid: false });
        }

        const payload = {
        //    fullNames: data.fullnames,
        //    phone,
            email: data.email,
         //   password: data.password,
        }
        dispatch(forgetPassword(payload))
        debugger
        if(status =='failed'){
            toastError('Email does not exist')
           }else if(status =='success') {
            setData({ ...data, isInvaliedemail: false,islinksend:true,email:''})
            
           }
    }
  return (
    <div className='fixed w-full h-[100%] flex md:flex-row  flex-col '>
        <div className='md:w-[50%] w-[100%] md:h-[100%] h-[80%] bg-white flex items-center justify-center'>
            <div className='md:w-[70%] w-[100%] md:min-h-[80%]  p-3 bg-white'>
                <div className='flex flex-row gap-2 items-center'>
                <Icon icon='fluent-emoji-high-contrast:japanese-passing-grade-button' color='#4f9d4a' fontSize={30} className='font-extrabold' />
                            <h1 className='font-bold tracking-[2px] text-orange-900 text-[23px]'><span className='text-everglade-600  font-bold'>Care</span>Web</h1>
                </div>

                {/* <div className='mt-[60px]'>
                    <h1 className='text-[40px] font-bold text-slate-800'>Create an Account</h1>
                    <h2 className='text-[#4c4c4c]'>Manage well your passwords.</h2>
                </div>
                <div className='flex flex-row gap-10 mt-[40px]'>
                    <button className='w-[180px] h-[50px] rounded-[20px] border-[1px] border-[#c4c4c4] flex flex-row gap-3 items-center justify-center'>
                        <img src='https://cdn.cdnlogo.com/logos/g/35/google-icon.svg' className='w-[20px] h-[20px] object-contain' />
                        <h1>Google</h1>
                    </button>
                    <button className='w-[180px] h-[50px] rounded-[20px] border-[1px] border-[#c4c4c4] flex flex-row gap-3 items-center justify-center'>
                        <img src='https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/Facebook_Logo_%282019%29.png/600px-Facebook_Logo_%282019%29.png' className='w-[20px] h-[20px] object-contain' />
                        <h1>Facebook</h1>
                    </button>
                </div>  
                <h1 className='ml-[100px] my-[20px]'>or continue with email</h1> */}
                <div className='md:w-[69%] w-[100%] '>
                    <form onSubmit={handleSignUp}>
                        {/* <div className='relative'>
                            <input onChange={(e) => setData({ ...data, fullnames: e.target.value, isEmailValid: true })} type='text' className='w-full h-[60px] border-[1px] border-[#c4c4c4] rounded-xl pl-[50px]' placeholder='Full names' required />
                            <Icon icon='material-symbols:person' color='#c4c4c4' fontSize={35} className='absolute top-3 left-2' />
                            { !data.isRequiredOn && data.fullnames.length === 0  && <h1 className='text-red-500'>This fullnames field should not be empty</h1>}
                        </div>
                        <div className='relative mt-[20px]'>
                            <PhoneInput
                                value={phone}  
                                defaultCountry='RW'
                                onChange={setPhone} className='phone-input w-full h-[60px] border-[1px] border-[#c4c4c4] rounded-xl pl-[50px]' placeholder='078 000 000 or 072 000 000' />
                            <Icon icon='ic:baseline-phone' color='#c4c4c4' fontSize={35} className='absolute top-3 left-2' />
                            { !data.isRequiredOn && (phone?.length === 0 || typeof phone === 'undefined') && <h1 className='text-red-500'>This phone field should not be empty</h1>}
                        </div> */}
                        <div className='relative mt-[20px]'>
                            <input value={data.email} onChange={(e) => setData({ ...data, email: e.target.value, isEmailValid: true })} type='email' className='w-full h-[60px] border-[1px] border-[#c4c4c4] rounded-xl pl-[50px] focus:border-everglade-500 focus:ring-1 focus:ring-everglade-500' placeholder='Email' />
                            <Icon icon='ic:baseline-mail-outline' color='#4f9d4a' fontSize={35} className='absolute top-3 left-2' />
                            { !data.isEmailValid && data.email.length > 0 && <h1 className='text-red-500'>This field should be an email</h1> } 
                            { !data.isRequiredOn && data.email.length === 0  && <h1 className='text-red-500'>This email field should not be empty</h1> } 
                        </div>
                        {/* <div className='relative mt-[20px]'>
                            <input onChange={(e) => setData({ ...data, password: e.target.value, isEmailValid: true })} type={data.isPasswordVisible ? 'text' : 'password'} className='w-full h-[60px] border-[1px] border-[#c4c4c4] rounded-xl pl-[50px]' placeholder='Password' />
                            <Icon icon='material-symbols:lock-outline' color='#c4c4c4' fontSize={35} className='absolute top-3 left-2' />
                            <Icon onClick={() => setData({ ...data, isPasswordVisible: !data.isPasswordVisible })} icon={data.isPasswordVisible ? 'mdi:eye-outline' : 'mdi:eye-off-outline'} color='#c4c4c4' fontSize={35} className='absolute top-3 right-[20px] cursor-pointer' />
                            {!data.isRequiredOn && data.password.length === 0  && <h1 className='text-red-500'>This password field should not be empty</h1> }
                        </div>*/}
                        <button onClick={handleSignUp} className='w-[100%] h-[60px] bg-everglade-600 hover:bg-everglade-400 text-white rounded-xl mt-[30px]'>{loading ? 'Loading...' : 'Forgot password'}</button>
                        <h1 className='mt-[10px]'>Looking for ? <Link to='/login' className='text-everglade-500 font-bold'>Login</Link></h1> 
                    </form>
                </div>

            </div>
        </div>
        <div className='md:w-[50%] h-[100%] bg-everglade-500 flex flex-col items-center justify-center'>
        <div className='md:w-[50%] h-[50%] bg-white flex flex-col items-center justify-center'>

            <img src={Img} className=' object-contain' alt='qa' /> 
            <h1 className='font-light text-[16px] mt-[10px] p-[20px]'>Use a longer password. Your password should be at least six characters long, although for extra security it should be even longer.</h1>
        </div>
        </div>
        
       {
        data.isError ?  
        <div id="alert-border-2" className="absolute top-2 right-0 flex p-4 mb-4 border-t-4 w-[600px] text-red-400 bg-gray-800 border-everglade-800" role="alert">
            <svg className="flex-shrink-0 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
            <div className="ml-3 text-sm font-medium">
                {error}
            </div>
            <button onClick={() => {
                setData({ ...data, isError: false });
            }} type="button" className="ml-auto -mx-1.5 -my-1.5rounded-lg focus:ring-2 focus:ring-red-400 p-1.5  inline-flex h-8 w-8 bg-gray-800 text-everglade-400 hover:bg-gray-700"  data-dismiss-target="#alert-border-2" aria-label="Close">
                <span className="sr-only">Dismiss</span>
                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
            </button>
        </div>
          : null
       } 
         {
        data.isInvaliedemail ?  
        <div id="alert-border-2" className="absolute top-2 right-3 flex p-4 mb-4 border-t-4 w-[600px] text-red-400 bg-gray-800 border-red-800" role="alert">
            <svg className="flex-shrink-0 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
            <div className="ml-3 text-sm font-medium">
                {successMessage}
            </div>
            <button onClick={() => {
                setData({ ...data, isInvaliedemail: false });
            }} type="button" className="ml-auto -mx-1.5 -my-1.5rounded-lg focus:ring-2 focus:ring-red-400 p-1.5  inline-flex h-8 w-8 bg-gray-800 text-red-400 hover:bg-gray-700"  data-dismiss-target="#alert-border-2" aria-label="Close">
                <span className="sr-only">isInvaliedemail</span>
                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
            </button>
        </div>
          : null
       } 
        {
        data.islinksend ?  
        <div id="alert-border-2" className="absolute top-2 right-3 flex p-4 mb-4 border-t-4 w-[600px] text-everglade-400 bg-gray-800 border-everglade-800" role="alert">
            <svg className="flex-shrink-0 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
            <div className="ml-3 text-sm font-medium">
                {successMessage}
            </div>
            <button onClick={() => {
                setData({ ...data, islinksend: false });
            }} type="button" className="ml-auto -mx-1.5 -my-1.5rounded-lg focus:ring-2 focus:ring-red-400 p-1.5  inline-flex h-8 w-8 bg-gray-800 text-everglade-400 hover:bg-gray-700"  data-dismiss-target="#alert-border-2" aria-label="Close">
                <span className="sr-only">Dismiss</span>
                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
            </button>
        </div>
          : null
       } 
    </div>
  )
}

export default SignUp;
