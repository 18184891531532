import * as http from "./api"

export const userLogin=async (data)=>{
  return await http.POST("doctorweblogin",data);
}
export const userInfo=async ()=>{
    return await http.GET("/api/auth/user");
}
export const DoctorDetails=async (id)=>{
  const userInfoData = JSON.parse(localStorage.getItem('userInfo')) || {};
  let URL = "";
  if(userInfoData && userInfoData.doctor_login && userInfoData.doctor_login.user_type && userInfoData.doctor_login.user_type.length > 0) {
    URL += `doctordetails/${id}_${userInfoData.doctor_login.user_type}`;
  } else {
    URL = `doctordetails/${id}`;
  }
  return await http.GET(URL)
}
export const updateDocotorDetails=async(data)=>{
  return await http.POST(`/docdetailsupdate`,data)
}
export const updateDocotorPassword=async(data)=>{
  return await http.POST(`/docpasswordupdate`,data)
}
export  const resetPassword = async(data)=>{
 return await http.POST(`/doctorpasswordreset`,data)
}
