import { Icon } from '@iconify/react'
import React from 'react'

const AddedComponent = ({ name, email, pastTime, icon }) => {
  return (
    <div className='w-[94%] flex flex-row justify-between items-center my-5'>
        <div className='flex flex-row gap-3 items-center'>
            <div className='w-[50px] h-[50px] border-[1px] rounded-xl flex items-center justify-center'>
                <img src={icon} className='w-[20px] h-[20px] object-contain' alt='l' />
            </div>
            <div>
                <h1 className='text-[16px] text-[#000]'>{name}</h1>
                <div className='flex flex-row gap-2 items-center'><h1 className='text-[12px] text-[#4c4c4c]'>{email}</h1> <div className='w-[5px] h-[5px] rounded-full bg-slate-400' /> <h1 className='text-[12px] text-[#4c4c4c]'>{pastTime}</h1></div>
            </div>
        </div>
        <div>
            <h1 className='text-indigo-500 text-[13px] font-semibold mb-[6px]'>Strong</h1>
            <div className='w-[200px] h-2 rounded-full bg-indigo-100 '>
                <div className='w-[70%] h-2 rounded-full bg-indigo-600' />
            </div>
        </div>
        <Icon icon='mdi:dots-horizontal' color='#c4c4c4' fontSize={40} />
    </div>
  )
}

export default AddedComponent