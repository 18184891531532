import { useEffect, useState } from "react";
import { AiFillDelete } from 'react-icons/ai';
import { FaEdit } from 'react-icons/fa';
import { MdAddCircle } from 'react-icons/md';
import { useDispatch, useSelector } from "react-redux";
import AddPredefinedMessage from "../Components/payment/addPredefinedMessageForm";
import Modal from "../Components/shared/modal";
import ConfirmModal from "../Components/shared/modal/confirm.modal";
import Table from "../Components/shared/table";
import { DoctorDetailsRequest } from "../redux/actions/authActions";
import { DeletePreMessageRequest } from "../redux/actions/chat";
const PredefinedMessage = ({ data, setOpenModal }) => {
    const [show,setShow]=useState(false);
    const [showUpdateModal,setShowUpdateModal]=useState(false);
    const { loading} = useSelector(state => state.chatPre.delete);
    const [showDeleteModal,setShowDeleteModal]=useState(false);
    const [row,setRow]=useState("");
    const dispatch = useDispatch();
    const { userInfo,doctorDetails} = useSelector((state) => state.auth);

    const columns = [
        {
            header: 'Text',
            key: 'key_word'
        },
        {
            header: 'Description',
            key: 'message'
        },
        {
            header: 'Actions',
            cell: (row) => (
                <div>
                    <button type="button" className="flex space-x-8">
                      <FaEdit size={20} className="text-everglade-700" onClick={()=>{
                        setShowUpdateModal(true);
                        setRow(row);
                      }}/>
                      <AiFillDelete size={20} className="text-rose-800" onClick={()=>{
                        setShowDeleteModal(true);
                        setRow(row);
                      }}/>
                    </button>
                    
                </div>
            )
        }
    ]
    useEffect(()=>{
        handleGetDoctorDetails();
        //eslint-disable-next-line
    },[]);
    const handleGetDoctorDetails = () => {
        dispatch(DoctorDetailsRequest({id:userInfo?.id}))
      }
    const handleSuccessfull=()=>{
        setShowDeleteModal();
        handleGetDoctorDetails();
    }

    const handleDelete=()=>{
        const params={
            id:row?.id,
            doctor_id:userInfo?.id
        }
        dispatch(DeletePreMessageRequest({ data: params, success: handleSuccessfull }));
    }

    return (
        <> 
            {show && <Modal className="mt-0 shadow-2xl" title="Add Predefined Message" onClick={()=>setShow(false)} close={()=>setShow(false)}> <AddPredefinedMessage  row={row} edit={false} url={doctorDetails?.data?.replacement_url} close={()=>{setShow(false);setRow("")}}/></Modal>}
            {showUpdateModal && <Modal title="Update Predefined Message" className="mt-0 shadow-2xl" onClick={()=>{setShowUpdateModal(false);setRow("")}} close={()=>{setShowUpdateModal(false);setRow("")}}> <AddPredefinedMessage url={doctorDetails?.data?.replacement_url}  row={row} edit={true} close={()=>setShowUpdateModal(false)}/></Modal>}
            {showDeleteModal &&  <ConfirmModal loading={loading}  onConfirm={handleDelete} onCancel={()=>setShowDeleteModal(false)}/>}
            <div className="w-[700px] lg:w-full h-min p-2">
                <Table columns={columns} data={doctorDetails?.data?.text_shortcuts} header={() => {
                    return (
                        <section className='w-full flex justify-between items-center'>
                            <h2 className="text-base font-semibold text-everglade-900">Predefined Messages</h2>
                            <button onClick={() => setShow(true)} className=' border flex justify-center items-center space-x-2 rounded-xl p-2 text-white bg-everglade-700 hover:bg-everglade-400'>
                                <MdAddCircle size={30} className='text-white cursor-pointer pr-2' />
                              Add new
                            </button>
                        </section>
                    );
                }} />
            </div>
        </>
    );
}
export default PredefinedMessage;