import axios from "axios";
import { getToken } from "../helpers/methods";
import { backendURL } from "../../config/api";
export const mainAPI = `${backendURL}/api/`;
const instance = axios.create({
    baseURL: mainAPI,
});
instance.interceptors.request.use(
    async (config) => {
        const token=getToken();
        config.headers["X-Auth-Token"]=token;
        
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
export const POST = instance.post;
export const GET = instance.get;
export const PUT = instance.put;
export const DELETE= instance.delete;