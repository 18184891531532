import { useSelector } from 'react-redux'
import '@sendbird/uikit-react/dist/index.css'
import { AiOutlineArrowLeft } from 'react-icons/ai'
import { Channel, SendBirdProvider } from '@sendbird/uikit-react'
import { SENDBIRD_INFO } from '../../../../utils/helpers/constant'
import { useLocation, useNavigate } from 'react-router-dom'
import { formatDistance } from 'date-fns'
import { useEffect } from 'react'
import { MdArchive, MdOutlineAddIcCall, MdOutlineArrowCircleLeft, MdUnarchive } from "react-icons/md";


const PrevChat = () => {
  const params = useLocation()
  const channel = params?.state?.channel
  const navigate = useNavigate()
  //const appId="71BB85F5-4AB3-4520-9681-B1A257FA20C5";
  const currentUser = useSelector((state) => state.auth)
  const token = currentUser.userInfo.doctor_login.sendbird_user_id
  const appId = SENDBIRD_INFO.appId

  const getName = () => {
    if (channel.name === 'Admin') {
      return 'ConnectCare Team'
    } else if (channel.name === 'DPCDoctor') {
      const filtered = channel.members.filter(
        (x) => x.userId.slice(0, 2) === 'PA'
      )
      return filtered?.map((item, index) =>
        filtered.length - 1 === index ? item.nickname : item.nickname + ','
      )
    } else if (channel.name === 'Teams') {
      const filtered = channel.members.filter(
        (x) =>
          x.userId !== currentUser?.userInfo?.doctor_login?.sendbird_user_id
      )
      return filtered?.map((item, index) =>
        filtered.length - 1 === index ? item.nickname : item.nickname + ','
      )
    }
  }
  const nickname = channel?.members
    ?.filter((item) => item?.userId !== token)
    .map((item) => item.nickname)
    .join(', ')


  function GoBack() {
    navigate(-1)
  }

  return (
    <div className='w-full h-full oveflow-y-auto relative border mb-5 '>
      {/* <div className='p-5  font-bold text-lg w-full'>
        <div className='flex flex-row items-center space-x-5'>
          <AiOutlineArrowLeft onClick={GoBack}  className=' hover:cursor-pointer' />
          <p>{nickname}</p>
        </div>
      </div> */}
      {/* <Link to={"/chat"} className='pt-4 cursor-pointer mb-8'><MdOutlineArrowCircleLeft size={30}/></Link> */}
      {/* <div className="chat flex flex-col w-full h-full fixed bottom-0 border flex relative">
                {token && <SendBirdProvider  appId={appId} userId={token} >
                <div className="w-full flex flex-col absolute  bottom-0 left-0 h-[90vh] overflow-y-auto sendbird-app__conversation-wrap">
                    <Channel renderMessageInput={()=><></>} channelUrl={channel?.url}  renderChannelHeader={() =><section className='flex items-center px-2'><MdOutlineArrowCircleLeft onClick={()=>navigate(-1)} className='cursor-pointer' size={30} /><p className='p-4 pl-8 font-semibold'>{getName()}</p></section> } />
                </div>
                </SendBirdProvider>}
            </div> */}
      <div className='h-[100%]'>
        <Channel channelUrl={channel._url} renderMessageInput={()=><></>} renderChannelHeader={()=> <div className='ml-5 mt-5 flex flex-row items-center space-x-5'>
          <MdOutlineArrowCircleLeft onClick={GoBack}  className="text-everglade-600 cursor-pointer " size={30} /><p className='p-4 pl-8 font-semibold'>{nickname}</p>
        </div> } />
      </div>
    </div>
  )
}
export default PrevChat
