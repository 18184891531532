import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { backendURL } from "../../config/api";
import { DoctorDetails,updateDocotorDetails,updateDocotorPassword,resetPassword } from "../../utils/services/authenticate";

export const forgetPassword = createAsyncThunk(
    'auth/doctorpasswordlink',
    async ({ email }, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
            
            const payload = {
                // names: fullNames,
                // phone,
                email: email.toLowerCase(),
               // password,
            }
            const result = await axios.post(`${backendURL}/api/doctorpasswordlink`, payload, config)
            return result;

        } catch (error) {
            if(error.response && error.response.data.message){
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
)

export const loginUser = createAsyncThunk(
    'auth/login',
    async ({ email, password,callBack }, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    // 'X-Auth-Type':'web'
                },
            }

            const payload = {
                email,
                password
            };

            const result = await axios.post(`${backendURL}/api/doctorweblogin`,JSON.stringify(payload), config);
           if(result.data.result=="success"){
            localStorage.setItem('userToken',JSON.stringify(result?.data?.acess_token));
            localStorage.setItem('userInfo',JSON.stringify(result?.data?.data));
            callBack();
           }            
            return result;
        } catch (error) {
            if(error.response && error.response.data.message){
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
)

export const getInfoUser = createAsyncThunk(
    'user/info',
    async ({ token }, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: token
                }
            }

            const result = await axios.get(`${backendURL}/api/auth/user`, config)
            return result;
        } catch (error) {
            if(error.response && error.response.data.message){
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
)

export const DoctorDetailsRequest = createAsyncThunk(
    'get/doctor-details',
    async ({id}, { rejectWithValue}) => {
        try {
            const result = await DoctorDetails(id);
            return result;
        } catch (error) {
            if(error.response && error.response.data.message){
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
)

export const UpdateDoctorDetailsRequest = createAsyncThunk(
    'post/doctor-details-update',
    async ({data,success}, { rejectWithValue}) => {
        try {
            const result = await updateDocotorDetails(data);
            success(result)
            return result;
        } catch (error) {
            if(error.response && error.response.data.message){
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
)

export const UpdateDoctorPasswordRequest = createAsyncThunk(
    'post/doctor-password-update',
    async ({data,success}, { rejectWithValue}) => {
        try {
            const result = await updateDocotorPassword(data);
            success()
            return result;
        } catch (error) {
            if(error.response && error.response.data.message){
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
)


export const ResetPasswordRequest = createAsyncThunk('post/reset-password',async({token,password,success},{rejectWithValue})=>{

    try {
        const data = {
            token,
            password
        }
      const result  = await resetPassword(data)
      if(result){
        success(result)
      }
        
    } catch (error) {
       if(error.response && error.response.data.message){
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            } 
    }

})
