import { Textarea } from 'flowbite-react'
import { useField } from 'formik'
import { useState } from 'react'

export const InputFloatingLabel = ({ label, className, ...props }) => {
  const [field, meta] = useField(props)
  return (
    <div className=''>
      <div className='relative z-0'>
        <input
          type='text'
          id='floating_standard'
          className={
            'block  py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-everglade-500 focus:outline-none focus:ring-0 focus:border-everglade-600 peer '
          }
          placeholder=' '
          {...field}
          {...props}
        />
        <br />
        <label
          htmlFor='floating_standard'
          className='absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-everglade-600 peer-focus:dark:text-everglade-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6'
        >
          {label}
        </label>
      </div>
      {meta.touched && meta.error ? (
        <span className='font-small text-red-700 mt-[4px]'>{meta.error}</span>
      ) : null}
    </div>
  )
}

export const InputText = ({ label, color, border, ...props }) => {
  const [field, meta] = useField(props)
  return (
    <div>
      <div className='relative z-0'>
        <label
          className={`font-small ${
            color ? 'text-everglade-700 font-bold ' : ''
          }`}
        >
          {label}
        </label>
        <input
          className={`h-[50px] ${color ? 'mt-3' : ''}  ${
            border
              ? ' border-b-2 border-black'
              : 'rounded border border-white-500'
          } p-2 appearance-none   w-full  text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline focus:border-everglade-500 focus:ring-1 focus:ring-everglade-500`}
          {...field}
          {...props}
        />
      </div>
      {meta.touched && meta.error ? (
        <span className='font-small text-red-700 mt-[2px]'>{meta.error}</span>
      ) : null}
    </div>
  )
}
export const InputTextArea = ({
  label,
  cols,
  color,
  rows,
  height,
  ...props
}) => {
  const [field, meta] = useField(props)
  return (
    <div>
      <div className='relative z-0'>
        <label
          className={`font-small ${
            color ? 'text-everglade-700 font-bold' : ''
          }`}
        >
          {label}
        </label>
        <Textarea
          cols={cols}
          rows={rows}
          className={`h-[${height ? height : '50px'}]  ${
            color ? 'mt-3' : ''
          } p-2 appearance-none border border-white-500 rounded w-full  text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline`}
          {...field}
          {...props}
        ></Textarea>
      </div>
      {meta.touched && meta.error ? (
        <span className='font-small text-red-700 mt-[4px]'>{meta.error}</span>
      ) : null}
    </div>
  )
}
export const InputFile = ({ label, handleChange, ...props }) => {
  const [field, meta, helpers] = useField(props)
  const { setValue } = helpers

  const acceptedFileTypes =
    '.doc, .docx, .pdf, .txt, .csv, .jpg, .jpeg, .png, .svg'
  const maxFileSize = 2 * 1024 * 1024 // 2MB
  const [fileError, setFileError] = useState('')

  return (
    <div>
      <div className='relative z-0'>
        <label className='font-small'>{label}</label>
        <input
          type='file'
          name=''
          {...props}
          multiple
          className='bg-everglade-500 mt-2 p-2 appearance-none border border-white-500 rounded w-full text-white mb-3 leading-tight focus:outline-none focus:shadow-outline'
          onChange={(e) => {
            const selectedFile = e.target.files[0]
            if (selectedFile) {
              if (selectedFile.size <= maxFileSize) {
                const fileName = selectedFile.name.toLowerCase()
                if (
                  fileName.endsWith('.doc') ||
                  fileName.endsWith('.docx') ||
                  fileName.endsWith('.txt') ||
                  fileName.endsWith('.csv') ||
                  fileName.endsWith('.jpg') ||
                  fileName.endsWith('.jpeg') ||
                  fileName.endsWith('.png') ||
                  fileName.endsWith('.svg') ||
                  fileName.endsWith('.pdf')
                ) {
                  setValue(fileName)
                  handleChange(e)
                  setFileError('')
                } else {
                  e.target.value = ''
                  setFileError('Please select a .doc, .docx, .pdf, .txt, .csv, .jpg, .jpeg, .png, or .svg file')
                }
              } else {
                e.target.value = ''
                setFileError('File size exceeds 2MB limit')
              }
            }
          }}
          accept={acceptedFileTypes}
        />
      </div>
      {meta.touched && meta.error ? (
        <span className='font-small text-red-700 mt-[4px]'>{meta.error}</span>
      ) : null}
      {fileError ? (
        <span className='font-small text-red-700 mt-[4px]'>{fileError}</span>
      ) : null}
    </div>
  )
}
export const InputSelect = ({ label, options, ...props }) => {
  const [field, meta] = useField(props)
  return (
    <div className=''>
      <div className='relative z-0'>
        <label className='font-small text-sm '>{label}</label>
        <select
          className='h-[50px]  p-2 appearance-none border border-white-500 rounded w-full focus:border-everglade-500 focus:ring-1 focus:ring-everglade-500 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline'
          placeholder=' '
          {...field}
          {...props}
        >
          <option value=''>Select Option</option>
          {options.map((item, index) => (
            <option key={index} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
      </div>
      {meta.touched && meta.error ? (
        <span className='font-small text-red-700 mt-[4px]'>{meta.error}</span>
      ) : null}
    </div>
  )
}
