import { Form, Formik, FieldArray } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { DoctorDetailsRequest } from "../../redux/actions/authActions";
import { PostPreMessageRequest,UpdatePreMessageRequest } from "../../redux/actions/chat";
import { toastError, toastModal } from "../../utils/helpers/methods";
import { InputText, InputTextArea,InputFloatingLabel,InputFile } from "../shared/input";
import Spinner from "../shared/spinner";
import { addPredefinedSchema } from "./schema";
import { useState } from "react";
import { getFileIcon } from '../../Components/shared/input/fileUtils'; // Assuming you've put the functions in a file called fileUtils.js
import { AiFillDelete } from "react-icons/ai";
import { IoIosAddCircle } from "react-icons/io";
import { useEffect } from "react";

const AddPredefinedMessage = ({ patientId, row,edit,close ,url}) => {
    const selector = useSelector(state => state.auth);
    const { userToken, userInfo } = useSelector((state) => state.auth);
    const { loading,error } = useSelector(state => state.chatPre.add);
    const dispatch = useDispatch();
    const [selectedFile, setSelectedFile] = useState([]);
    const [deleteSelectedFile, setDeleteSelectedFile] = useState([]);
    const [tempFileList, setTempFileList] = useState([]);
    const handleFileChange = (event) => {
    const file = event.target.files; 
    setSelectedFile(file);
    };
    useEffect(() => {
        setTempFileList(edit?row?.file_name:[]);
    },[]);
    const initialValues = {
        key_word: edit?row?.key_word:"",
        message: edit?row?.message:"",
        replacement_link:edit  ? [...row.replacement_link] : [],
        file_name:edit?row?.file_name:[],
    }
    const handleSuccessfull = () => {
        toastModal("Successfull Done");
        setTimeout(() => {
            dispatch(DoctorDetailsRequest({id:userInfo?.id}));
            close();
        }, 100)
    }
    const handleFailure = (msg) => {
        toastError("key word already exist")
        setTimeout(() => {
            dispatch(DoctorDetailsRequest({id:userInfo?.id}));
            close();
        }, 100)
    }
    const removeFile = (file, index) => {
        const removeTempString = tempFileList.filter((e, i) => i != index);
        setTempFileList(removeTempString)
        let deleteobj = [...deleteSelectedFile, file]
        setDeleteSelectedFile(deleteobj)
    }
    const handleSubmit = (values) => {
            var formData = new FormData();
            formData.append('doctor_id', userInfo?.id);
            formData.append('key_word', values.key_word.toLowerCase());
            formData.append('message', values.message);
            //formData.append('file_name', JSON.stringify(values.file_name));
            //formData.append('replacement_link', JSON.stringify(values.replacement_link?values.replacement_link:[]));
            values.replacement_link.forEach((link, index) => {
                if(link){
                formData.append(`replacement_link[${index}]`, link);
                }
            });
            if(userInfo && userInfo.doctor_login && userInfo.doctor_login.user_type && userInfo.doctor_login.user_type.length > 0){
                formData.append('user_type', userInfo.doctor_login.user_type);
            }
            if(deleteSelectedFile.length>0){
              deleteSelectedFile.forEach((delete_files, index) => {
                formData.append(`delete_files[${index}]`, delete_files);
              });
            }
            if (selectedFile.length > 0) {
                selectedFile.forEach((file, index) => {
                 formData.append(`replacement_file[${index}]`, file);
                });
            }  
            if (edit) {
                formData.append('id', row?.id);
                dispatch(UpdatePreMessageRequest({ data:formData, success: handleSuccessfull,failure:handleFailure }));
              } else {
                dispatch(PostPreMessageRequest({ data: formData, success: handleSuccessfull ,failure:handleFailure}));
            }
   }
    return (
        <>
           
            <div className="w-[600px] h-min p-2 mt-0 ">
                <section className="px-4 w-full">
                    <Formik initialValues={initialValues} enableReinitialize={true} onSubmit={handleSubmit} validationSchema={addPredefinedSchema} >
                        <Form >
                            <section className="flex flex-col w-full">
                                <InputFloatingLabel name="key_word" label="Shortcut" />
                                <InputTextArea name="message" cols={15} rows={5} color={true} height={'fit'} label="Description" placeholder="description" />
                                {/* <InputText name="replacement_link" label="Link" color={true} placeholder="Enter the Link" /> */}
                                {/* Use FieldArray to handle replacement_link */}
                               
                                <FieldArray 
                                className="w-full"
                                    name="replacement_link"
                                    render={arrayHelpers => (
                                        <>
                                            <label className={`flex font-small ${'text-everglade-700 font-bold '}`}>
                                                Links
                                                  <IoIosAddCircle size={20} className="text-everglade-800" onClick={() => arrayHelpers.push('')}/>
                                            </label>
                                            {(arrayHelpers.form.values.replacement_link || []).map((link, index) => (
                                                <div key={index} className="flex ">
                                                    <InputText
                                                        name={`replacement_link.${index}`}
                                                        label={`Link ${index + 1}`}
                                                        color={true}
                                                        placeholder="Enter the Link"
                                                    />
                                                    <AiFillDelete size={20} className="text-red-800" onClick={() => arrayHelpers.remove(index)} />
                                                </div>
                                            ))}


                                        </>
                                    )}
                                />

                                <label className={`font-small ${'text-everglade-700 font-bold ' }`}>
                                    Upload File
                                </label>
                              
                                {tempFileList?.length>0 && tempFileList.map((file,i)=>{
                                    return (
                                     <div className='flex flex-row justify-items-start'>
                                     <div className='flex flex-col justify-evenly pl-3'>
                                      {getFileIcon(file,url)}
                                     </div>
                                     <div className='flex flex-row justify-evenly pl-3'>
                                     <div className='flex flex-col justify-evenly pl-3'>
                                         <h1 className='font-extrabold'>{file?.filename}</h1>
                                     </div>
                                     <div className='flex flex-col justify-evenly pl-3'>
                                     <AiFillDelete size={20} className="text-red-800"onClick={() => removeFile(file?.filename,i)}/>
                                     </div>
                                     </div>

                                 </div>
                                 )
                                })}
                                {/* {(row?.file_name.length>0) &&
                                
                                    <div className='flex flex-row justify-items-start'>
                                        {getFileIcon(row.file_name,url)}
                                        <div className='flex flex-col justify-evenly pl-3'>
                                            <h1 className='font-extrabold'>{row?.file_name}</h1>
                                        </div>
                                    </div>
                                } */}
                                <div className="pt-5">
                                {/* <input className="bg-everglade-300"multiple type="file" onChange={handleFileChange} /> */}
                                <InputFile name="file" accept=".doc,.docx,.pdf,.txt,.csv,.jpg,.jpeg,.png,.svg" label="File" handleChange={handleFileChange}/>
                                </div>
                            </section>
                            <section className="flex justify-center items-center mb-5">
                                {edit?
                                    <button type="submit"
                                    disabled={loading}
                                    className="lg:flex border-2   mt-3.5 w-[153PX] h-14 lg:mt-12 rounded border-care-green bg-everglade-700 hover:bg-everglade-400  hover:border-everglade-400 uppercase flex items-center justify-center text-sm poppins font-medium text-white">
                                    {loading ? <Spinner /> : 'Update'}
                                </button>
                                :
                                <button type="submit"
                                    disabled={loading}
                                    className="lg:flex border-2   mt-3.5 w-[153PX] h-14 lg:mt-12 rounded border-everglade-700 bg-everglade-700 hover:bg-everglade-400 hover:border-everglade-400   uppercase flex items-center justify-center text-sm poppins font-medium text-white">
                                    {loading ? <Spinner /> : 'Submit'}
                                </button>
                                }
                                <button type="button"
                                    disabled={loading}
                                    onClick={()=>close()}
                                    className="lg:flex border-2 ml-5  mt-3.5 w-[153PX] h-14 lg:mt-12 rounded border-everglade-700 bg-white hover:bg-[#F5F5F5]  hover:border-everglade-400   uppercase flex items-center justify-center text-sm poppins font-medium text-everglade-700">
                                    Cancel
                                </button>
                            </section>
                        </Form>
                    </Formik>
                </section>
            </div>
        </>
    );
}
export default AddPredefinedMessage;