import * as Yup from "yup"

export const addPaymentSchema=Yup.object({
    amount:Yup.number().required('Amount is required'),
    purpose:Yup.string().required('Purpose is required'),
    requester:Yup.string().required('Requester is required'),
    date:Yup.date().required('Date is required'),
})
export const addDocSchema=Yup.object({
    title:Yup.string().required('Title is required'),
    // file:Yup.string().required('Document is required'),
})
export const addPredefinedSchema=Yup.object({
    key_word:Yup.string().required('Text is required'),
    message:Yup.string().required('Description is required'),
})