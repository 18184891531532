import { Icon } from '@iconify/react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { UpdateDoctorPasswordRequest } from '../redux/actions/authActions'
import { getdoctorinfo } from '../redux/actions/passActions'
import { logout } from '../redux/slices/authenticate'
import { toastModal } from '../utils/helpers/methods'

const ChangePassword = () => {
  const [data, setData] = useState({
    password: '',
    isPasswordVisible: false,
    confirmPassword: '',
    isConfirmPasswordVisible: false,
  })

  const navigate = useNavigate()

  const [matchError, setMatchError] = useState(false)
  const [hasChar, setHasChar] = useState(false)
  const [lengthError, setLengthError] = useState(false)

  const dispatch = useDispatch()
  const { userToken, userInfo, updatePassword } = useSelector(
    (state) => state.auth
  )

  const { docinfo } = useSelector((state) => state.pass)

  useEffect(() => {
    dispatch(getdoctorinfo({ token: userToken, docid: userInfo.id }))
    //eslint-disable-next-line
  }, [])

  function success() {
    setTimeout(() => {
      localStorage.clear()
      navigate('/')
      dispatch(logout())
    }, 5000)
    toastModal('Password Updated succesfully', 5000)
  }

  function hasSpecialCharacter(password) {
    const specialCharacters = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/
    return specialCharacters.test(password)
  }

  const handleUpdate = (e) => {
    e.preventDefault()
    const body = {
      practitioner_id: docinfo?.doctor_login?.practitioner_id,
      password: data.password,
      type: 'update',
      user_type: docinfo?.doctor_login?.user_type
    }
    dispatch(UpdateDoctorPasswordRequest({ data: body, success }))
  }

  function onChange(e) {
    const newPassword = e.target.value
    const hasSpecialChar = hasSpecialCharacter(newPassword)

    setData({ ...data, password: newPassword })

    if (!hasSpecialChar) {
      setHasChar(true)
    } else {
      setHasChar(false)
    }

    if (newPassword.length < 6) {
      setLengthError(true)
    } else {
      setLengthError(false)
    }

    if (newPassword === '') {
      setHasChar(false)
      setLengthError(false)
    }
  }

  function Onchange(e) {
    const newConfirmPassword = e.target.value

    setData({ ...data, confirmPassword: newConfirmPassword })

    if (newConfirmPassword !== data.password) {
      setMatchError(true)
    } else {
      setMatchError(false)
    }

    if (newConfirmPassword === '') {
      setMatchError(false)
    }
  }

  return (
    <div className=''>
      <h1 className='text-[25px] font-bold text-everglade-800 p-4'>
        Change password
      </h1>
      <div className='mx-auto w-[50%] flex justify-center'>
        <ul className='list-disc text-red-600 '>
          <li>Password must have atleast one special character</li>
          <li>Password must be greater than 6 character</li>
        </ul>
      </div>
      <div className='border p-5 mt-[80px] rounded-md shadow-2xl w-[50%] mx-auto'>
        <div className='p-10'>
          <div className='w-full relative'>
            <input
              value={data.password}
              onChange={(e) => onChange(e)}
              type={data.isPasswordVisible ? 'text' : 'password'}
              className='w-full h-[60px]  pl-[50px] input focus:border-everglade-500 focus:ring-1 focus:ring-everglade-500'
              placeholder='Password'
            />
            <Icon
              onClick={() =>
                setData({ ...data, isPasswordVisible: !data.isPasswordVisible })
              }
              icon={
                data.isPasswordVisible
                  ? 'mdi:eye-outline'
                  : 'mdi:eye-off-outline'
              }
              color='#4f9d4a'
              fontSize={35}
              className='absolute top-3 right-[20px] cursor-pointer'
            />
            {hasChar && (
              <p className='mt-4 text-red-600'>
                Password must have atleast one special character
              </p>
            )}
            {lengthError && (
              <p className='mt-4 text-red-600'>
                Password should be greater length 6
              </p>
            )}
          </div>
          <div className='w-full relative mt-10'>
            <input
              value={data.confirmPassword}
              onChange={(e) => Onchange(e)}
              type={data.isConfirmPasswordVisible ? 'text' : 'password'}
              className='w-full h-[60px]   pl-[50px] input focus:border-everglade-500 focus:ring-1 focus:ring-everglade-500'
              placeholder='confirm password'
            />
            <Icon
              onClick={() =>
                setData({
                  ...data,
                  isConfirmPasswordVisible: !data.isConfirmPasswordVisible,
                })
              }
              icon={
                data.isConfirmPasswordVisible
                  ? 'mdi:eye-outline'
                  : 'mdi:eye-off-outline'
              }
              color='#4f9d4a'
              fontSize={35}
              className='absolute top-3 right-[20px] cursor-pointer'
            />
            {matchError && (
              <p className='mt-4 text-red-600'>Passwords do not match'</p>
            )}
          </div>
          <div className='mt-10'>
            <button
              onClick={handleUpdate}
              className='w-[100%] border h-[60px] bg-everglade-600 hover:bg-everglade-400 cursor-pointer text-white rounded-xl'
              disabled={
                hasChar ||
                matchError ||
                lengthError ||
                !data.password ||
                !data.confirmPassword
              }
            >
              {updatePassword.loading ? 'Updating....' : 'Update'}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChangePassword
