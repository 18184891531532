import React from 'react'
import { Link } from 'react-router-dom'

const NotFound = () => {
  return (
    <div className='w-[100] flex flex-col items-center justify-center'>
      <h1 className='text-[100px] font-extrabold text-indigo-600'>Oops</h1>
      <h1 className='text-[90px] font-extrabold text-red-400'>404 Page Not Found!</h1>
      <Link className='mt-5 text-[25px]  underline font-bold text-indigo-600' to={'/login'}>
          Go to Login
      </Link>
    </div>
  )
}

export default NotFound