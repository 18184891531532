export const SENDBIRD_INFO = {
  appId:'18C5DFF5-E69F-4397-82DF-3DD9EC8BF18E', //prod,
  sendBirdToken:'f235fc53bd95780fd9f73ba812b1df980984ec2f' //prod
  // appId : '3C092CBF-8C5C-4900-A8E5-F6E39299F4C4',//local
  // sendBirdToken:'287b5f0516be88d2ab39ce7940c59acace29e822'//local
  };


// const one = {
//   name:"Hello Test",
//   id:"PA16"
// }  
// const two = {
//   name:"jeremy",
//   id:"PR1"
// }  
// const thre = {
//   name:"first users",
//   id:"PA10828"
// }  
// const four = {
//   name:"Anastasia Benson, D.O",
//   id:"PR11"
// }  
// const five = {
//   name:"Annette Marin",
//   id:"PR10"
// } 
//  const six = {
//   name:"mellisa",
//   id:"PR3"
// }  
// const seven ={
//   name:"nikolas",
//   id:"PA14"
// }
 