/* eslint-disable jsx-a11y/no-redundant-roles */
import { useEffect, useState,useRef } from 'react'
import Spinner from '../spinner'
import { useDispatch, useSelector } from 'react-redux'
import { PatientAvailableNonAvailableRequest } from '../../../redux/actions/passActions'
import Avatar from './avatar'
import Table from '../table'
import { Icon } from '@iconify/react'
import { useNavigate, useLocation } from 'react-router-dom'
import Pagination from '../../pagination'
import {
  PatientListfilter,
  getPatientList,
} from '../../../redux/actions/passActions'
import { toastError } from '../../../utils/helpers/methods'
import { Listbox } from '@headlessui/react'
import { backendURL } from '../../../config/api'

const ChatWithPatient = ({ loading, activeTab, data, handleSelected }) => {
  const { userToken, userInfo } = useSelector((state) => state.auth)
  const { available_patients, unavailable_patients } = useSelector(
    (state) => state.patient.getAvailableNonAvailable.data
  )

  const { PatientListinfo, PatientList, Patientfilter } = useSelector(
    (state) => state.pass
  )
  let patientUrl = `${backendURL}/img/patient`

  const dispatch = useDispatch()
  const [patient_login, setPatient_login] = useState('')
  const [PatientFilterval, setPatientFilterval] = useState({
    group: '',
    tag: '',
    search: '',
    patient_login,
  })
  


  const location = useLocation()

  const [PatientFilter, setPatientFilter] = useState([])

  useEffect(() => {
    //   dispatch(getListPasswords({ token: userToken }));
    const data = location?.state
    setPatientFilterval({ ...PatientFilterval, search: data })
    let obj = {
      token: userToken,
      practitioner_id: userInfo.id,
      employer_filter: '',
      patient_tag: '',
      search_key: data ? data : '',
      limit: 5,
      patient_login,
    }
    dispatch(getPatientList(obj))
    dispatch(
      PatientListfilter({
        practitioner_id: userInfo.id,
        token: userToken,
      })
    )

    setTimeout(() => {
      if (PatientListinfo && PatientListinfo.data) {
        setPatientFilter(Patientfilter)
        //   setPatientList(PatientListinfo.data.patients)
      }
    }, 500)
    //eslint-disable-next-line
  }, [])

  const navigate = useNavigate()

  const [currentPage, setCurrentPage] = useState(1)

  function handlePageChange(pageNumber) {
    let obj = {
      token: userToken,
      practitioner_id: userInfo.id,
      employer_filter: PatientFilterval.group,
      patient_tag: PatientFilterval.tag,
      search_key: PatientFilterval.search,
      page: pageNumber,
      patient_login,
    }
    dispatch(getPatientList(obj))
  }

  const goToChat=async(item)=>{
    const user = item.patient_login.sendbird_user_id != undefined && item.patient_login.sendbird_user_id != null;
    if (user) {
      handleSelected(item);
    } else {
      toastError("Not able to chat at this moment.");
    }
  }

  const handlefilter = () => {
    let obj = {
      token: userToken,
      practitioner_id: userInfo.id,
      employer_filter: PatientFilterval.group,
      patient_tag: PatientFilterval.tag,
      search_key: PatientFilterval.search,
      patient_login,
    }
    dispatch(getPatientList(obj))
  }

  const columns = [
    {
      header: '',
      cell: (row) => (
        <Avatar
          fname={!row?.address?.profile_image && row?.address?.first_name}
          lname={!row?.address?.profile_image && row?.address?.last_name}
          image={
            row?.address?.profile_image
              ? `${patientUrl}/${row?.address?.profile_image}`
              : ''
          }
          className={' cursor-pointer'}
          showStatus={false}
          onClick={() => navigate(`/patientDetails/${row.id}`)}
        />
      ),
    },
    {
      header: 'Names',
      cell: (row) => (
        <div
          className=' cursor-pointer'
          onClick={() => navigate(`/patientDetails/${row.id}`)}
        >
          {row?.address?.first_name} {row?.address?.last_name}{' '}
        </div>
      ),
    },
    {
      header: 'Chat',
      cell: (row) => (
        <>
          {
             row?.patient_login  && 
             row?.patient_login.sendbird_user_id!=null && 
             row?.practice!=null&&
             row?.practice.def_provider_id!=null &&
          (
            <Icon
              icon={'humbleicons:chat'}
              fontSize={30}
              className='text-everglade-500 cursor-pointer'
              onClick={() => goToChat(row)}
            />
          )}
        </>
      ),
    },
  ]

  function FilterType(e) {
    switch (e) {
      case 1:
      setPatient_login('1')
        return 
      case 2:
      setPatient_login('2')
        return 
      default:
      setPatient_login('')
        return 
    }
  }

  const handleChangesearch = (event) => {
    setPatientFilterval({ ...PatientFilterval, search: event.target.value })
  }
  const handleChangegroup = (event) => {
    setPatientFilterval({ ...PatientFilterval, group: event.target.value })
  }


  function Filters(id) {
    switch (id) {
      case "1":
        return 'Available'
      case "2":
        return 'Unvailable'
      default:
        return 'All'
    }
  }


 
  const [isGroupOpen, setIsGroupOpen] = useState(false)
  const groupDropDownRef = useRef(null)



  
  const toggleGroupDropdown = () => {
    setIsGroupOpen((prev) => !prev)
  }

  const [selectedGroupName, setSelectedGroupName] = useState('')


  
  const handleGroupChange = (value) => {
    setPatientFilterval({ ...PatientFilterval, group: value })
    setSelectedGroupName(Patientfilter?.groups[value])
    setIsGroupOpen(false)
  }

  
  const handleGroupClickOutside = (event) => {
    if (
      groupDropDownRef.current &&
      !groupDropDownRef.current.contains(event.target)
    ) {
      setIsGroupOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleGroupClickOutside)

    return () => {
      document.removeEventListener('click', handleGroupClickOutside)

    }
  }, [])

  return (
    <div className='w-full mx-auto mt-[0px] space-y-4 px-10 h-[100%]'>
      {/* <div className='w-full bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700'>
        <div className="flow-root">
          <ul role="list" className="divide-y divide-gray-200 dark:divide-gray-700">
          {loading && <section className='flex-1 w-full h-1/2 flex justify-center items-center'><Spinner /></section>}
          {activeTab === 1 &&
          !loading &&
          available_patients?.length > 0 &&
          available_patients?.map((item, index) => (
              <section
                className='flex space-x-4 p-2 flex-row w-full items-center cursor-pointer'
                key={index}
                onClick={() => handleSelected(item)}
              >
                <Avatar fname={!item.address.profile_image&&item?.address?.first_name} lname={!item.address.profile_image&&item?.address?.last_name}  image={item.address.profile_image?`${patientUrl}/${item.address.profile_image}`:''} />
                <div className='flex flex-row items-center space-x-4'>
                <p className='capitalize cursor-pointer'>
                  {item?.address?.first_name}{' '}
                </p>
                <p>{item?.address?.last_name}</p>
                </div>
              </section>
          ))}
          </ul>
          <ul role="list" className="divide-y divide-gray-200 dark:divide-gray-700">
          {activeTab === 2 &&
          !loading &&
          unavailable_patients?.length > 0 &&
          unavailable_patients?.map((item, index) => (
            <section className='flex space-x-4 p-2 flex-row w-full items-center cursor-pointer' key={index}>
              <Avatar fname={!item.address.profile_image&&item?.address?.first_name} lname={!item.address.profile_image&&item?.address?.last_name}  image={item.address.profile_image?`${patientUrl}/${item.address.profile_image}`:''} />
                <div className='flex flex-row items-center space-x-4'>
                <p className='capitalize cursor-pointer'>
                  {item?.address?.first_name}{' '}
                </p>
                <p>{item?.address?.last_name}</p>
                </div>
            </section>
          ))}
          </ul>
        </div>  
      </div> */}
      <Table
        count={10}
        defaultPage={1}
        page={currentPage}
        onChange={handlePageChange}
        totalPages={PatientListinfo?.data?.total_pages}
        columns={columns}
        data={PatientList?.slice(0,8)}
        header={() => {
          return (
            <div className='w-full  grid grid-cols-1 lg:grid-cols-4  justify-between items-center'>
              <h1 className='text-base w-[300px] font-semibold text-everglade-900 pl-2 shrink-0 '>
                Chat with patient
              </h1>
              <div
                className={`w-full px-10 lg:px-0 flex col-span-3 flex-wrap  gap-2  items-center`}
              >
                {
                        <Listbox
                          onChange={FilterType}
                          value={patient_login}
                        >
                          <Listbox.Button className='w-[130px] h-[35px] bg-everglade-800 rounded-full flex flex-row justify-center items-center gap-2'>
                            <Icon
                              icon='ic:round-plus'
                              color='#ffbe0b'
                              fontSize={18}
                            />
                            <p className='text-white'>
                              {Filters(patient_login)}
                            </p>
                          </Listbox.Button>
                          <Listbox.Options className='absolute z-10 w-[120px] p-1 mt-[125px] bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none '>
                            <Listbox.Option
                              value={''}
                              className={
                                'hover:bg-everglade-500 hover:text-white p-2 '
                              }
                            >
                              All
                            </Listbox.Option>
                            <Listbox.Option
                              value={1}
                              className={
                                'hover:bg-everglade-500 hover:text-white p-2'
                              }
                            >
                              Available
                            </Listbox.Option>
                            <Listbox.Option
                              value={2}
                              className={
                                'hover:bg-everglade-500 hover:text-white p-2'
                              }
                            >
                              Unavailable
                            </Listbox.Option>
                          </Listbox.Options>
                        </Listbox>
                      }
                {Patientfilter && (
                        <div className='w-[350px]'>
                        <div className='relative w-full' ref={groupDropDownRef}>
                          <div
                            className='w-full bg-white border rounded p-2 cursor-pointer'
                            onClick={toggleGroupDropdown}
                          >
                            {selectedGroupName|| 'Group'}
                          </div>
                          {isGroupOpen && (
                            <div
                              className='absolute w-full   h-[300px] overflow-y-auto  bg-white border rounded border-gray-300 shadow-md z-10  top-full'
                              style={{ top: 'auto', marginTop: '2px' }}
                            >
                              {Object.entries(Patientfilter.groups).map(([key,value],idx) => (
                                <div
                                  key={key}
                                  className='p-2 cursor-pointer hover:bg-everglade-500 hover:text-white flex justify-between items-center'
                                  onClick={() => handleGroupChange(key)}
                                >
                                  {value}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                      )}
                {Patientfilter && (
                  <div className='relative shrink-0 '>
                    <input
                      value={PatientFilterval.search}
                      onChange={handleChangesearch}
                      className='shrink-0 relative h-[40px] rounded-md border-[0.5px]  focus:outline-none focus:border-everglade-800 pl-[40px]  focus:ring-everglade-800'
                      placeholder='Search'
                    />
                    <Icon
                      icon='ic:sharp-search'
                      fontSize={24}
                      className='absolute top-2 left-2 text-everglade-800 '
                    />
                  </div>
                )}

                {Patientfilter && (
                  <button
                    onClick={handlefilter}
                    className=' p-2 w-[150px] h-[35px] bg-everglade-800 hover:bg-everglade-400 rounded-md flex items-center justify-center'
                  >
                    <p className='text-white'>Submit</p>
                  </button>
                )}
              </div>
            </div>
          )
        }}
      />
      <div className='my-3 flex  justify-center pr-4 w-full md:justify-end'>
              {PatientListinfo?.data?.total_pages > 1 && <Pagination count={10} defaultPage={1} page={currentPage} onChange={handlePageChange} totalPages={PatientListinfo?.data?.total_pages} />}
            </div>
      {/* {activeTab === 1 && !loading && available_patients?.length === 0 && (
        <span className='w-full text-center '>No list found</span>
      )}
      {activeTab === 2 && !loading && unavailable_patients?.length === 0 && (
        <span className='w-full text-center '>No list found</span>
      )} */}
    </div>
  )
}
export default ChatWithPatient
