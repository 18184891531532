import { createSlice } from "@reduxjs/toolkit";
import { getPatientList, getPatientDetails,getdoctorinfo,PatientListfilter } from "../actions/passActions";

const initialState = {
    loading: false,
    passwords: [],
    error: null,
    successMessage: null
}

const passwordsSlice = createSlice({
    name: 'password',
    initialState,
    reducers: {},
    extraReducers: {
        // [registerPassword.pending]: (state) => {
        //     state.loading = true;
        //     state.error = null;
        // },
        // [registerPassword.fulfilled]: (state, { payload }) => {
        //     state.loading = false;
        //     state.successMessage = payload.data.message;
        // },
        // [registerPassword.rejected]: (state, { payload }) => {
        //     state.loading = false;
        //     state.error = payload
        // },
        [getPatientList.pending]: (state) => {
            state.loading = true;
            state.error = null;
        },
        [getPatientList.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.PatientListinfo = payload?.data;
            state.PatientList = payload?.data?.data?.patients;
            state.successMessage = payload?.data?.message;
            state.status = payload?.data?.result;
        },
        [getPatientList.rejected]: (state, { payload }) => {
            state.loading = false;
            state.error = payload?.data?.message;
        },
        [getdoctorinfo.pending]: (state) => {
            state.loading = true;
            state.error = null;
        },
        [getdoctorinfo.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.docinfo = payload?.data?.data;
            state.successMessage = payload?.data?.message;
            state.status = payload?.data?.result;
        },
        [getdoctorinfo.rejected]: (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        },
        [getPatientDetails.pending]: (state) => {
            state.loading = true;
            state.error = null;
        },
        [getPatientDetails.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.Patientinfo = payload?.data?.data;
            state.successMessage = payload?.data?.message;
            state.status = payload?.data?.result;
        },
        [getPatientDetails.rejected]: (state, { payload }) => {
            state.loading = false;
            state.error = payload?.data?.message;
        },
        [PatientListfilter.pending]: (state) => {
            state.loading = true;
            state.error = null;
        },
        [PatientListfilter.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.Patientfilter = payload?.data?.data;
            state.successMessage = payload?.data?.message;
            state.status = payload?.data?.result;
        },
        [PatientListfilter.rejected]: (state, { payload }) => {
            state.loading = false;
            state.error = payload?.data?.message;
        },


    }
});

export default passwordsSlice.reducer;
