import { Icon } from '@iconify/react'
import React from 'react'
import AddedComponent from './AddedComponent'

const Passwords = () => {
  return (
    <div className='w-full'>
        <div className='w-full flex flex-row justify-between'>
            <h1 className='text-[25px] font-bold text-black'>Passwords</h1>
            <div className='flex flex-row gap-5 items-center'>
                <button className='w-[100px] h-[35px] bg-indigo-500 rounded-full flex flex-row justify-center items-center gap-2'>
                    <Icon icon='ic:round-plus' color='#ffbe0b' fontSize={18} />
                    <p className='text-white'>New</p>
                </button>
                <div className='relative'>
                    <input className='w-[80%] h-[40px] rounded-xl border-[1px] border-[#c4c4c4] focus:border-indigo-500 pl-[40px]' placeholder='Search' />
                    <Icon icon='ic:sharp-search' color='#4361ee' fontSize={24} className='absolute top-2 left-2' />
                </div>
            </div>
        </div>
        <div className='mt-[60px]'>
            { [1,2,3].map((item, idx) => <AddedComponent key={idx} icon={'https://cdn.worldvectorlogo.com/logos/invision.svg'} name='InvisionApp' email='invi@newsite.com' pastTime='2d ago' />)}
        </div>
    </div>
  )
}

export default Passwords