import { formatDistance } from 'date-fns';
import Avatar from './avatar';
import { FaVideo } from 'react-icons/fa'
import { VscCallOutgoing } from "react-icons/vsc";
import { MdFilePresent } from "react-icons/md";

const ChanneLItemPreview = ({currentUser, item, onClick, isMoveToPractice, moveToPractice}) => {
  
  const gotToChatSpace=(e)=>{
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    onClick && onClick(item);
  }

  const getChannelInfo = () => {
    const { members, name } = item;
    switch (name) {
      case 'Admin':
        return  { name: "ConnectCare Team" };
      case 'Teams':{
        const filtered = members.filter((x) => x.userId !== currentUser?.userId);
        const memName= filtered.map((item, index) => (filtered.length - 1 === index ? item.nickname : item.nickname + ','));
      
        const status= filtered[0]?.connectionStatus
        return { name: memName[0], status:status};
      }
      case 'DPCDoctor':
        const filteredPA = members.filter((x) => x.userId.slice(0, 2) === 'PA');
        const memName= filteredPA.map((item, index) => (filteredPA.length - 1 === index ? item.nickname : item.nickname + ','));
        return {name: memName[0], status:filteredPA[0]?.connectionStatus}
      default:
        return { name: null };
    }
  };

  const onClickMoveToDefault = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    moveToPractice(item);
  }

  const channelInfo = getChannelInfo();

  const filterArray = item?.members?.filter(member => member?.metaData?.patient !== "false")

  return (
    <ul role="list" className="divide-y divide-gray-200 dark:divide-gray-700">
      <li className="py-3 sm:py-4" onClick={gotToChatSpace}>
        <div className="flex items-center space-x-4">
          <div className="flex-shrink-0">
            {filterArray.length===0?
            <Avatar showStatus={true} fname={channelInfo.name} lname="" image={ item?._name==='Admin'?'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSC-Is9vPh4AgDCULO33AmoIpSvXcaJ51VrRZQUDXxl0YjDDAq94jciUm7zt7SB4OD5UhA&usqp=CAU': item?.coverUrl} status={channelInfo.status} />
          :
          <Avatar showStatus={true} fname={!(filterArray[0]?.plainProfileUrl)?filterArray[0].nickname:channelInfo.name} lname="" image={ item?._name==='Admin'?'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSC-Is9vPh4AgDCULO33AmoIpSvXcaJ51VrRZQUDXxl0YjDDAq94jciUm7zt7SB4OD5UhA&usqp=CAU': !(filterArray[0]?.plainProfileUrl)?'':filterArray[0]?.plainProfileUrl} status={channelInfo.status} />
        }
          </div>
          <div className="flex-1 min-w-0">
            <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                {channelInfo.name}
            </p>
            {/* <div className="flex gap-4">
              {item?.lastMessage?.customType === 'VIDEO_CALL' ? (
                item?.creator?.userId === currentUser?.userId ? <div className="flex items-center text-green-500">
                  <FaVideo className="mr-2" /> Call
                </div>:<p>{item?.creator?.nickname}</p>
              ) : (
                <p className="text-sm">
                  {((item?.lastMessage?.message)?.length > 100) ? `${(item?.lastMessage?.message)?.slice(0, 100) + '   ........'}` : item?.lastMessage?.message}·
                </p>
              )}

              <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                {formatDistance(new Date(item?.lastMessage?.createdAt), new Date(), { addSuffix: true })}
              </p>
            </div> */}
            <div className="flex gap-4">
              {/* {(item?.lastMessage?.customType === 'VIDEO_CALL' )&& (
                item?.creator?.userId === currentUser?.userId ? <div className="flex items-center text-green-500">
                  <FaVideo className="mr-2" /> Call
                </div>:<p>{item?.creator?.nickname}</p>
              )} */}
              {(item?.lastMessage?.messageType!=="file"&&item?.lastMessage?.customType!= "VIDEO_CALL") && <p className="text-sm">{((item?.lastMessage?.message)?.length > 100)?`${(item?.lastMessage?.message)?.slice(0,100)+'   ........'}`:item?.lastMessage?.message} </p>}
              {item?.lastMessage?.messageType=="file" && <p className="flex text-sm  text-gray-900 "><MdFilePresent className='m-1' />{item?.lastMessage?.name} </p>} 
              {item?.lastMessage?.customType== "VIDEO_CALL"&& <p className="flex text-sm text-everglade-900 "><VscCallOutgoing className='m-1'/>Call</p>} 
              <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                {formatDistance(new Date(item?.lastMessage?.createdAt), new Date(), { addSuffix: true })}
              </p>
              {item?.data && !isMoveToPractice &&<p className="text-sm text-orange-900 truncate dark:text-gray-400">
              Assigned to {item.members.find(x=>x.userId==item.data).nickname}
              </p>}
            </div>
          </div>
          {isMoveToPractice && <span onClick={onClickMoveToDefault} className='font-bold mr-2 inline-flex min-w-0 hover:cursor-pointer text-white bg-everglade-600 p-2'>
           {"Move to Practice channel"} </span>}
          {item.unreadMessageCount>0 && <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
            <div className=" -top-2 -right-2 bg-red-600 text-white rounded-full px-2 py-1 text-xs">
              {item?.unreadMessageCount}
            </div>
          </div>
          }

        </div>
      </li>
    </ul>
  );
}
export default ChanneLItemPreview;