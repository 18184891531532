import * as http from "./api";
export const PostPaymentRequest=async (data)=>{
   return await http.POST("patpay_doctorweb_request",data);
}
export const PostDocumentRequest=async (data)=>{
   return await http.POST("patdocumentsigning",data);
}
export const GetDownloadDocument=async (doc_id)=>{
   return await http.GET(`downloadsigningdoc/${doc_id}`);
}
export const GetAvailableNonAvailable=async (payload)=>{
   return await http.POST(`providerpatientlogin`,payload);
}