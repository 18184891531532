import { useNavigate } from 'react-router-dom';
import { formatDistance} from 'date-fns'

const PrevChanneLItemPreview = ({currentUser,item,onClick,practiceName}) => {
  const navigate=useNavigate();
  const gotToChatSpace=()=>{
      // navigate(`/chats`,{state:{channel:item}});
      onClick && onClick(item);
      // (curren)
  }
  const getName=()=>{
    const user=item.members.filter(x=>x.userId!==currentUser?.userInfo?.doctor_login?.sendbird_user_id);
    if(item.name==="Admin")
    {
      return "ConnectCare Team"
    }
    else if(item.name==="Teams")
    {
     
      const filtered=item.members.filter((x)=>x.userId!==currentUser?.userId);
      
        return filtered?.map((item,index)=>filtered.length-1===index?item.nickname:item.nickname+",");
    }
    else if(item.name==="DPCDoctor")
    { 
      const filtered=item.members.filter((x)=>x.userId.slice(0,2)==="PA");
      return filtered?.map((item,index)=>filtered.length-1===index?item.nickname:item.nickname+",");
    }
  }
  return (
    <div className='w-full min-h-[50px]' onClick={gotToChatSpace}>
      <button className="w-full text-left py-2 focus:outline-none focus-visible:bg-indigo-50 border-b p-2">
        <div className="flex items-center">
          <section className='rounded-full items-start flex-shrink-0 mr-3 h-[40px] w-[40px] rounded-full border-2'>
          <img className="w-full h-full object-cover object-center rounded-full" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT-HmAlYRaMiTx6PqSGcL9ifkAFxWHVPvhiHQ&usqp=CAU" alt="Marie Zulfikar" />
          </section>
          
          <div>
            <h4 className="text-sm font-semibold w-full text-gray-900 flex text-ellipsis">{practiceName}</h4>
            <div className="text-[13px]">{item?.lastMessage?.message} · {formatDistance(new Date(item?.lastMessage?.createdAt),new Date(),{ addSuffix: true })}</div>
          </div>
        </div>
      </button>
    </div>
  );
}
export default PrevChanneLItemPreview;