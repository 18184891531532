import { useEffect, useState } from "react";

const ChatPagination = ({page, onChange, hasNext}) => {

    const [active, setActive] = useState(page);

    useEffect(() => {
       setActive(page);
    }, [page])

    const handleActivePage = (page) => {
        setActive(page);
        onChange && onChange(page);
    }

    const handleNextPage = () => {
        handleActivePage(active + 1);
    }

    const handlePrevPage = () => {
        active > 1 && handleActivePage(active - 1);
    }

    return (
        <nav aria-label="Page navigation example">
            <ul className="inline-flex -space-x-px">
                <li style={active === 1 ? {pointerEvents: 'none'}: {}} onClick={handlePrevPage}>
                    <a href="#" className="px-3 py-2 ml-0 leading-tight text-gray-500 hover:text-white border border-gray-300 rounded-l-lg hover:bg-everglade-600">Previous</a>
                </li>
                <li>
                    <a href="#" className={`px-3 py-2 leading-tight border border-gray-300 ${'bg-everglade-600 text-everglade-600 hover:bg-everglade-600 hover:text-white text-white'}`}>{active}</a>
                </li>
                <li style={hasNext ? {}: {pointerEvents: 'none'}} onClick={handleNextPage}>
                    <a href="#" className="px-3 py-2  leading-tight  text-gray-500  border border-gray-300 rounded-r-lg hover:bg-everglade-600 hover:text-white">Next</a>
                </li>
            </ul>
        </nav>

    )
}
export default ChatPagination;