// import { SENDBIRD_INFO } from "../utils/helpers/constant";
import { useSelector } from "react-redux";
// import SendbirdProvider from "@sendbird/uikit-react/SendbirdProvider";
import ChatPreview from "../Components/shared/chat/chatPreview";
import {useLocation} from 'react-router-dom'

const ChatPage = () => {
  const location = useLocation()
  const data = location.state
    // const store = useSendbirdStateContext();
    const userId = useSelector(state => state.auth);
    // const sdk = store?.stores.sdkStore.sdk;
    // const token = userId.userInfo.doctor_login.sendbird_user_id;
    // const appId =SENDBIRD_INFO.appId;

    return (
      <div className="w-full h-screen relative ">
      <div className="chat w-full h-full  flex relative pr-5 pb-5">
      {/* <SendbirdProvider appId={appId} userId={token}> */}
      {userId && <ChatPreview newType={data} />}
      {/* </SendbirdProvider> */}
  </div>
  </div>
    );
};
export default ChatPage; 