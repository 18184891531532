import { Icon } from '@iconify/react';
import { useEffect, useState } from 'react';
import { MdAddCircle } from 'react-icons/md';
import Table from '../shared/table/';

const PayRequest = ({ data, setOpenModal }) => {
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [filterData, setFilterData] = useState([]);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  useEffect(() => {
    let filteredData = data;
  
    if (searchQuery) {
      filteredData = filteredData.filter((item) =>
        item.requester.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
  
    if (fromDate) {
      filteredData = filteredData.filter(
        (item) => new Date(item.request_datetime) >= new Date(fromDate)
      );
    }
  
    if (toDate) {
      const toDateWithTime = new Date(toDate);
      toDateWithTime.setHours(23, 59, 59, 999); // Set to the end of the day
      filteredData = filteredData.filter(
        (item) => new Date(item.request_datetime) <= toDateWithTime
      );
    }
  
    setFilterData(filteredData);
  }, [searchQuery, data, fromDate, toDate]);
  
  const totalPages = Math.ceil(filterData.length / itemsPerPage);

  
  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const debounceSearch = (func, delay) => {
    let timeoutId;
    return function(...args) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const handleSearch = debounceSearch((value) => {
    setSearchQuery(value);
    setCurrentPage(1);
  }, 300);

  const columns = [
    {
      header: 'Requester',
      key: 'requester',
    },
    {
      header: 'Date',
      key: 'request_datetime',
    },
    {
      header: 'Status',
      cell: (row) => (
        <div  className={` p-2 px-5 rounded-xl  flex justify-center items-center w-[110px]  ${
          row.status === 1
            ? 'bg-orange-900 text-white'
            : 'bg-everglade-500 text-white'
        }`}>
          <span className='p-2 rounded-xl text-center text-white'>
            {row?.status==2?'Completed':'Pending'}
          </span>
        </div>
      ),
    },
    {
      header: 'Amount',
      cell: (row) => (
        <div className='text-sm bg-everglade-500 text-center rounded-md border min-w-[100px] flex justify-center flex-1'>
          <span className='p-2 rounded-xl text-center text-white'>
            ${row?.amount}
          </span>
        </div>
      ),
    },
  ];

  return (
    <div className='w-[700px] lg:w-full h-min p-2'>
      <Table
        title={'Payment Requests'}
        columns={columns}
        data={filterData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)}
        header={() => (
          <section className='w-full flex justify-between items-center'>
            <h2 className='text-base text-everglade-800 font-semibold'>
              Payment Requests
            </h2>
            <div className='flex gap-5'>
              <div className=' flex gap-5 items-center'>
                <label>From</label>
                <input
                type='date'
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
                className='h-[40px] rounded-md border-[0.5px] pl-2 focus:outline-none focus:border-everglade-800 focus:ring-everglade-800'
              />
              </div>
              <div className=' flex gap-5 items-center'>
                <label>To</label>
              <input
                type='date'
                value={toDate}
                min={fromDate}
                onChange={(e) => setToDate(e.target.value)}
                className='h-[40px] rounded-md border-[0.5px] pl-2 focus:outline-none focus:border-everglade-800 focus:ring-everglade-800'
              />
              </div>
            </div>
            <div className='relative shrink-0'>
              <input
                className='shrink-0 relative h-[40px] rounded-md border-[0.5px]  focus:outline-none focus:border-everglade-800 pl-[40px]  focus:ring-everglade-800'
                placeholder='Search'
                onChange={(e) => handleSearch(e.target.value)}
              />
              <Icon
                icon='ic:sharp-search'
                fontSize={24}
                className='absolute top-2 left-2 text-everglade-800'
              />
            </div>
            <button
              onClick={() => setOpenModal()}
              className=' bg-everglade-500 hover:bg-everglade-400  border text-white flex justify-center items-center space-x-2 rounded p-2'
            >
              <MdAddCircle size={30} className='text-white  cursor-pointer' />
              <span>Add Payment Request</span>
            </button>
          </section>
        )}
      />
      <div className='pagination flex  items-center mt-4 justify-end'>
        <div className='flex flex-row items-center gap-5'>
          <p className='text-gray-500'>Total count of {filterData.length}</p>
          <div>
            <button
            onClick={handlePrevious}
            className={`mx-1 px-4 py-2 bg-gray-200 text-gray-600 rounded ${
              currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              className={`mx-1 px-4 py-2 rounded ${
                index + 1 === currentPage
                  ? 'bg-everglade-500 text-white'
                  : 'bg-gray-200 text-gray-600'
              }`}
              onClick={() => setCurrentPage(index + 1)}
              disabled={index + 1 === currentPage}
            >
              {index + 1}
            </button>
          ))}
          <button
            onClick={handleNext}
            className={`mx-1 px-4 py-2 bg-gray-200 text-gray-600 rounded ${
              currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            disabled={(currentPage === totalPages) || filterData.length===0}
          >
            Next
          </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayRequest;
