import { Icon } from '@iconify/react'
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Img from '../images/icon.png'

import { useDispatch } from 'react-redux'
import { ResetPasswordRequest } from '../redux/actions/authActions'

function PasswordReset() {
  let error = null
  let successMessage = null
  const navigate = useNavigate()
  const { token } = useParams()
  const [resetdata, resetData] = useState({
    email: '',
    password: '',
    cpassword: '',
    error: '',
    successMessage: '',
    isRequiredOn: true,
    passwordmatch: false,
    //  isPasswordVisible: false,
    // credentialsInvalid: false
  })
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()

  const handleSubmit = async (event) => {
    event.preventDefault()
    if (
      resetdata.cpassword.length === 0 ||
      resetdata.password.length === 0
      // || data.password.length === 0 ||
      // typeof phone === 'undefined'
    ) {
      resetData({ ...resetdata, isRequiredOn: false })
      return false
    } else if (resetdata.cpassword !== resetdata.password) {
      resetData({ ...resetdata, passwordmatch: true, isRequiredOn: false })
      return false
    }

    function success(result) {
      alert(result.message)
      setLoading(false)
      localStorage.clear()
      navigate('/')
    }

    dispatch(
      ResetPasswordRequest({
        token: token,
        password: resetdata.cpassword,
        success,
      })
    )
  }

  //   if (loading) {
  //     return <div>Loading...</div>;
  //   }

  //   if (!user) {
  //     return <div>Invalid or expired token.</div>;
  //   }

  return (
    <div className='fixed w-full h-[100%] flex md:flex-row flex-col '>
      <div className='md:w-[50%] md:h-[100%] bg-white flex items-center justify-center'>
        <div className='md:w-[70%] min-h-[80%] p-3 bg-white'>
          <div className='flex flex-row gap-2 items-center'>
            {/* <Icon icon='fluent-emoji-high-contrast:japanese-passing-grade-button' color='#4f9d4a'fontSize={30} className='font-extrabold' /> */}
            <h1 className='font-bold tracking-[2px] text-orange-900 text-[23px]'>
              <span className='text-everglade-600 font-bold'>Reset</span>
              Password
            </h1>
          </div>

          {/* <div className='mt-[60px]'>
                <h1 className='text-[40px] font-bold text-slate-800'>Create an Account</h1>
                <h2 className='text-[#4c4c4c]'>Manage well your passwords.</h2>
            </div>
            <div className='flex flex-row gap-10 mt-[40px]'>
                <button className='w-[180px] h-[50px] rounded-[20px] border-[1px] border-[#c4c4c4] flex flex-row gap-3 items-center justify-center'>
                    <img src='https://cdn.cdnlogo.com/logos/g/35/google-icon.svg' className='w-[20px] h-[20px] object-contain' />
                    <h1>Google</h1>
                </button>
                <button className='w-[180px] h-[50px] rounded-[20px] border-[1px] border-[#c4c4c4] flex flex-row gap-3 items-center justify-center'>
                    <img src='https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/Facebook_Logo_%282019%29.png/600px-Facebook_Logo_%282019%29.png' className='w-[20px] h-[20px] object-contain' />
                    <h1>Facebook</h1>
                </button>
            </div>  
            <h1 className='ml-[100px] my-[20px]'>or continue with email</h1> */}
          <div className='md:w-[69%]'>
            <form onSubmit={handleSubmit}>
              <div className='relative mt-[20px]'>
                <input
                  value={resetdata.password}
                  onChange={(e) =>
                    resetData({
                      ...resetdata,
                      password: e.target.value,
                      isEmailValid: true,
                    })
                  }
                  type={resetdata.isPasswordVisible ? 'text' : 'password'}
                  className='w-full h-[60px] border-[1px] border-[#c4c4c4] rounded-xl pl-[50px]'
                  placeholder='Password'
                />
                <Icon
                  icon='material-symbols:lock-outline'
                  color='#c4c4c4'
                  fontSize={35}
                  className='absolute top-3 left-2'
                />
                <Icon
                  onClick={() =>
                    resetData({
                      ...resetdata,
                      isPasswordVisible: !resetdata.isPasswordVisible,
                    })
                  }
                  icon={
                    resetdata.isPasswordVisible
                      ? 'mdi:eye-outline'
                      : 'mdi:eye-off-outline'
                  }
                  color='#c4c4c4'
                  fontSize={35}
                  className='absolute top-3 right-[20px] cursor-pointer'
                />
                {!resetdata.isRequiredOn && resetdata.password.length === 0 && (
                  <h1 className='text-red-500'>
                    This password field should not be empty
                  </h1>
                )}
              </div>
              <div className='relative mt-[20px]'>
                <input
                  value={resetdata.cpassword}
                  onChange={(e) =>
                    resetData({
                      ...resetdata,
                      cpassword: e.target.value,
                      isEmailValid: true,
                    })
                  }
                  type={resetdata.isPasswordVisible ? 'text' : 'password'}
                  className='w-full h-[60px] border-[1px] border-[#c4c4c4] rounded-xl pl-[50px]'
                  placeholder='Confirm Password'
                />
                <Icon
                  icon='material-symbols:lock-outline'
                  color='#c4c4c4'
                  fontSize={35}
                  className='absolute top-3 left-2'
                />
                <Icon
                  onClick={() =>
                    resetData({
                      ...resetdata,
                      isPasswordVisible: !resetdata.isPasswordVisible,
                    })
                  }
                  icon={
                    resetdata.isPasswordVisible
                      ? 'mdi:eye-outline'
                      : 'mdi:eye-off-outline'
                  }
                  color='#c4c4c4'
                  fontSize={35}
                  className='absolute top-3 right-[20px] cursor-pointer'
                />
                {!resetdata.isRequiredOn &&
                  resetdata.cpassword.length === 0 && (
                    <h1 className='text-red-500'>
                      This password field should not be empty
                    </h1>
                  )}
                {!resetdata.isRequiredOn && resetdata.passwordmatch && (
                  <h1 className='text-red-500'>Passwords do not match</h1>
                )}
              </div>
              <button
                onClick={handleSubmit}
                className='w-[100%] h-[60px] bg-everglade-600 text-white rounded-xl mt-[30px]'
              >
                {loading ? 'Loading...' : 'Reset password'}
              </button>
            </form>
          </div>
        </div>
      </div>
      <div className='md:w-[50%] h-[100%] bg-everglade-500 flex flex-col items-center justify-center'>
        <div className='md:w-[50%] h-[50%] bg-white flex flex-col items-center justify-center'>
          <img src={Img} className=' object-contain' alt='qa' />
          <h1 className='font-light text-[16px] mt-[10px] p-[20px]'>
            Use a longer password. Your password should be at least six
            characters long, although for extra security it should be even
            longer.
          </h1>
        </div>
      </div>
      {/* <div className='md:w-[50%] h-[50%] bg-white flex flex-col items-center justify-center'>

            <img src={Img} className=' object-contain' alt='qa' /> 
            <h1 className='font-light text-[16px] mt-[10px] p-[20px]'>Use a longer password. Your password should be at least six characters long, although for extra security it should be even longer.</h1>
        </div> */}

      {resetdata.isError ? (
        <div
          id='alert-border-2'
          className='absolute top-2 left-0 flex p-4 mb-4 border-t-4 w-[600px] text-everglade-400 bg-gray-800 border-everglade-800'
          role='alert'
        >
          <svg
            className='flex-shrink-0 w-5 h-5'
            fill='currentColor'
            viewBox='0 0 20 20'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fill-rule='evenodd'
              d='M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z'
              clip-rule='evenodd'
            ></path>
          </svg>
          <div className='ml-3 text-sm font-medium'>{error}</div>
          <button
            onClick={() => {
              resetData({ ...resetdata, isError: false })
            }}
            type='button'
            className='ml-auto -mx-1.5 -my-1.5rounded-lg focus:ring-2 focus:ring-red-400 p-1.5  inline-flex h-8 w-8 bg-gray-800 text-everglade-400 hover:bg-gray-700'
            data-dismiss-target='#alert-border-2'
            aria-label='Close'
          >
            <span className='sr-only'>Dismiss</span>
            <svg
              aria-hidden='true'
              className='w-5 h-5'
              fill='currentColor'
              viewBox='0 0 20 20'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fill-rule='evenodd'
                d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                clip-rule='evenodd'
              ></path>
            </svg>
          </button>
        </div>
      ) : null}
      {resetdata.isInvaliedemail ? (
        <div
          id='alert-border-2'
          className='absolute top-2 left-0 flex p-4 mb-4 border-t-4 w-[600px] text-red-400 bg-gray-800 border-red-800'
          role='alert'
        >
          <svg
            className='flex-shrink-0 w-5 h-5'
            fill='currentColor'
            viewBox='0 0 20 20'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fill-rule='evenodd'
              d='M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z'
              clip-rule='evenodd'
            ></path>
          </svg>
          <div className='ml-3 text-sm font-medium'>{successMessage}</div>
          <button
            onClick={() => {
              resetData({ ...resetdata, isInvaliedemail: false })
            }}
            type='button'
            className='ml-auto -mx-1.5 -my-1.5rounded-lg focus:ring-2 focus:ring-red-400 p-1.5  inline-flex h-8 w-8 bg-gray-800 text-red-400 hover:bg-gray-700'
            data-dismiss-target='#alert-border-2'
            aria-label='Close'
          >
            <span className='sr-only'>isInvaliedemail</span>
            <svg
              aria-hidden='true'
              className='w-5 h-5'
              fill='currentColor'
              viewBox='0 0 20 20'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fill-rule='evenodd'
                d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                clip-rule='evenodd'
              ></path>
            </svg>
          </button>
        </div>
      ) : null}
      {resetdata.islinksend ? (
        <div
          id='alert-border-2'
          className='absolute top-2 left-0 flex p-4 mb-4 border-t-4 w-[600px] text-everglade-400 bg-gray-800 border-everglade-800'
          role='alert'
        >
          <svg
            className='flex-shrink-0 w-5 h-5'
            fill='currentColor'
            viewBox='0 0 20 20'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fill-rule='evenodd'
              d='M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z'
              clip-rule='evenodd'
            ></path>
          </svg>
          <div className='ml-3 text-sm font-medium'>{successMessage}</div>
          <button
            onClick={() => {
              resetData({ ...resetdata, islinksend: false })
            }}
            type='button'
            className='ml-auto -mx-1.5 -my-1.5rounded-lg focus:ring-2 focus:ring-red-400 p-1.5  inline-flex h-8 w-8 bg-gray-800 text-everglade-400 hover:bg-gray-700'
            data-dismiss-target='#alert-border-2'
            aria-label='Close'
          >
            <span className='sr-only'>Dismiss</span>
            <svg
              aria-hidden='true'
              className='w-5 h-5'
              fill='currentColor'
              viewBox='0 0 20 20'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fill-rule='evenodd'
                d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                clip-rule='evenodd'
              ></path>
            </svg>
          </button>
        </div>
      ) : null}
    </div>
  )
}

export default PasswordReset

