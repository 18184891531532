import { Icon } from '@iconify/react'
import { useEffect, useState } from 'react'
import { AiOutlineDownload } from 'react-icons/ai'
import { MdAddCircle } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { downloadDocument } from '../../redux/actions/passActions'
import { mainAPI } from '../../utils/services/api'
import Spinner from '../shared/spinner'
import Table from '../shared/table'
import { Listbox } from '@headlessui/react'


const DocumentRequest = ({ data, setOpenModal }) => {
  const dispatch = useDispatch()
  const selector = useSelector((state) => state.patient)
  const [loading, setLoading] = useState(false)
  const [searchQuery, setSearchQuery] = useState('');
  const [filterData, setFilterData] = useState([]);
    const [filterStatus, setFilterStatus] = useState('');

  const itemsPerPage = 8
  const [currentPage, setCurrentPage] = useState(1)

  const totalPages = Math.ceil(filterData.length / itemsPerPage)

  const handleClick = (page) => {
    setCurrentPage(page)
  }

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1)
    }
  }

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  const { downloadDocument: downloadState } = selector

  const handleSuccessfull = (success, id) => {
    if (success && id !== '') {
      window.location = `${mainAPI}downloadsigningdoc/${id}`
    }
    setLoading(false)
  }

  const downloadFile = (row) => {
    setLoading(row.id)
    if (row.id !== '') {
      dispatch(
        downloadDocument({ doc_id: row.id, successfull: handleSuccessfull })
      )
    }
  }
  const columns = [
    {
      header: 'Document Title',
      key: 'document_title',
    },
    {
      header: 'Document Status',
      cell: (row) => (
        <span
          className={` p-2 px-5 rounded-xl  flex justify-center items-center w-[110px]  ${
            row.status === 1
              ? 'bg-orange-900 text-white'
              : 'bg-everglade-500 text-white'
          }`}
        >
          {row.status === 1 ? 'Not-Signed' : 'Signed'}{' '}
        </span>
      ),
    },
    {
      header: 'Action',
      cell: (row) => (
        <button
          onClick={() => downloadFile(row)}
          className='p-2 border bg-everglade-500  text-white flex  justify-center items-center space-x-2 rounded hover:bg-everglade-300'
        >
          {loading === row.id ? (
            <Spinner />
          ) : (
            <>
              <AiOutlineDownload
                size={30}
                className=' text-white cursor-pointer'
              />
              <span>Download</span>
            </>
          )}
        </button>
      ),
    },
  ]


  useEffect(() => {
    if (searchQuery) {
      const filteredData = data.filter((item) =>
        item.document_title.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilterData(filteredData);
    }
    if (filterStatus) {
     const filteredData = data.filter((item) =>
        filterStatus === 'All'
          ? true
          : filterStatus === 'Signed'
          ? item.status !== 1
          : item.status === 1
      )
      setFilterData(filteredData);
    } else {
      setFilterData(data);
    }
  }, [searchQuery, data,filterStatus]);

  


  const debounceSearch = (func, delay) => {
    let timeoutId;
    return function(...args) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const handleSearch = debounceSearch((value) => {
    setSearchQuery(value);
    setCurrentPage(1);
  }, 300);


  return (
    <>
      <div className='w-[700px] lg:w-full h-min p-2'>
        {/* <li className="px-4 py-3 hover:bg-gray-50">
                    <div className="flex items-center justify-between">
                        <div className="text-sm font-medium text-gray-900 flex flex-col flex-1">
                            <h1 className='font-weight no-italic text-xl capitalize'>{data?.document_title}</h1>
                            <small className=''>{data?.created_at}</small>
                        </div>
                        <div className='flex-1  flex justify-start'>
                            <button onClick={downloadFile} className='p-2 border flex justify-center items-center space-x-2 rounded'>
                                {loading ? <Spinner /> :
                                    <><AiOutlineDownload size={30} className='text-main-indigo cursor-pointer' />
                                        <span>Download</span></>
                                }
                            </button>
                        </div>
                        <div className="text-sm text-gray-500 w-[130px]">
                            <span className={`border-2 p-2 px-5 rounded-xl  flex justify-center items-center  ${data.status === 1 ? 'border-red-600 text-red-600' : 'border-care-green text-care-green'}`}>{data.status === 1 ? 'Not-Signed' : 'Signed'} </span>
                        </div>
                    </div>
                    <div className="mt-1 text-sm text-gray-700">
                    </div>
                </li> */}
        <Table
          title={'Payment Requests'}
          columns={columns}
          data={filterData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)}
          header={() => {
            return (
              <section className='w-full flex justify-between items-center'>
                <h2 className='text-base text-everglade-800 font-semibold'>
                  Document Requests
                </h2>
              <div>
               <Listbox
                  value={filterStatus}
                  onChange={(value) => setFilterStatus(value)}
                >
                  <Listbox.Button className='w-[130px] h-[35px] bg-everglade-800 rounded-full flex flex-row justify-center items-center gap-2'>
                    <Icon
                      icon='ic:round-plus'
                      color='#ffbe0b'
                      fontSize={18}
                    />
                    <p className='text-white'>
                      Filter
                    </p>
                  </Listbox.Button>
                  <Listbox.Options className='absolute z-10 w-[120px] p-1 bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none  hover:cursor-pointer '>
                    <Listbox.Option
                      value={'Signed'}
                      className={
                        'hover:bg-everglade-500 hover:text-white p-2'
                      }
                    >
                      Signed
                    </Listbox.Option>
                    <Listbox.Option
                      value={'Not-Signed'}
                      className={
                        'hover:bg-everglade-500 hover:text-white p-2'
                      }
                    >
                      Not-Signed
                    </Listbox.Option>
                  </Listbox.Options>
                </Listbox>
                </div>                 
                <div className='relative shrink-0'>
              <input
                className='shrink-0 relative h-[40px] rounded-md border-[0.5px]  focus:outline-none focus:border-everglade-800 pl-[40px]  focus:ring-everglade-800'
                placeholder='Search'
                onChange={(e) => handleSearch(e.target.value)}
              />
              <Icon
                icon='ic:sharp-search'
                fontSize={24}
                className='absolute top-2 left-2 text-everglade-800'
              />
            </div>
                <button
                  onClick={() => setOpenModal()}
                  className=' bg-everglade-500  hover:bg-everglade-400 text-white border flex justify-center items-center space-x-2 rounded p-2'
                >
                  <MdAddCircle
                    size={30}
                    className='text-white cursor-pointer'
                  />
                  <span>Add Document Request</span>
                </button>
              </section>
            )
          }}
        />
        <div className='pagination flex  items-center mt-4 justify-end'>
          <div className='flex flex-row items-center gap-5'>
            <p className=' text-gray-500'> total count of {filterData.length}</p>
            <div>
              <button
                onClick={handlePrevious}
                className='mx-1 px-4 py-2 bg-gray-200 text-gray-600 rounded disabled:opacity-50 disabled:cursor-not-allowed'
                disabled={currentPage === 1}
              >
                Previous
              </button>
              {Array.from({ length: totalPages }, (_, index) => (
                <button
                  key={index}
                  className={`mx-1 px-4 py-2 rounded ${
                    index + 1 === currentPage
                      ? 'bg-everglade-500 text-white'
                      : 'bg-gray-200 text-gray-600'
                  }`}
                  onClick={() => handleClick(index + 1)}
                  disabled={index + 1 === currentPage}
                >
                  {index + 1}
                </button>
              ))}
              <button
                onClick={handleNext}
                className='mx-1 px-4 py-2 bg-gray-200 text-gray-600 rounded disabled:opacity-50 disabled:cursor-not-allowed'
                disabled={(currentPage === totalPages) || filterData.length===0}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default DocumentRequest
