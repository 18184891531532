import { FaRegFilePdf, FaRegFileWord } from 'react-icons/fa';


const getFileIcon = (data,url) => {
  const fileExtension = getFileExtension(data?.filename);
  if (fileExtension === 'pdf') {
    return <div className="w-1 pt-1"><FaRegFilePdf color="#4f9d4a" /></div>;
  }
  else if (fileExtension === 'doc' || fileExtension === 'docx') {
    return <div className="w-1 pt-1"><FaRegFileWord color="#4f9d4a" /></div>;
  }
  else if (fileExtension === "jpeg" || fileExtension === "jpg" || fileExtension === "png") {
    return <div className="w-10 m-1"><img src={url+'/'+ data?.filename} alt="Preview" /></div>;
  }
}

const getFileExtension = (data) => {
  if(data){
  return data.split('.').pop().toLowerCase(); 
}
};

export { getFileIcon, getFileExtension };
