import {AiOutlineClose, AiOutlineCloseCircle} from 'react-icons/ai';
const Modal = ({title,children,close,className}) => {
    return (
        <>  
        <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">

<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

<div className="fixed inset-0 z-10 w-screen overflow-y-none">
           <div className="w-full h-full bg-black fixed top-0 opacity-5 left-0  p-2" style={{zIndex:"100px"}}></div>
            <div className="w-full h-full ml-auto flex justify-center fixed items-start top-0 pt-40  left-0 overflow-y-auto  z-40  p-10" style={{zIndex:"1000px"}}>
                <section className={`bg-white h-min rounded-xl  ${className}`}>
                    <section className="h-min w-full p-4 flex justify-between">
                        <h1 className='font-bold text-everglade-800 text-xl'>
                            {title}
                        </h1>
                      <AiOutlineClose size={30}  className='justify-end text-everglade-700 hover:text-red-600' onClick={()=>close()}/>
                    </section>
                    <section className='overflow-auto w-full h-min'>
                       {children}
                    </section>
                </section>
            </div>
            </div>
            </div>
             </>
    );
}
export default Modal
