import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { backendURL } from "../../config/api";
import { GetAvailableNonAvailable, GetDownloadDocument, PostDocumentRequest, PostPaymentRequest } from "../../utils/services/patient";


export const getdoctorinfo = createAsyncThunk(
    'getdoctorInfo',
    async ({token ,docid,success}, { rejectWithValue }) => {
        try {
            const userInfo = JSON.parse(localStorage.getItem('userInfo')) || {};
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'X-Auth-Token': token
                }
            }
            // const payload = {
            //     place,
            //     username,
            //     password,
            //     icon
            // }
            let URL = "";
            if(userInfo && userInfo.doctor_login && userInfo.doctor_login.user_type && userInfo.doctor_login.user_type.length > 0) {
                URL += `${backendURL}/api/doctorwebdetails/${docid}_${userInfo.doctor_login.user_type}`;
            } else {
                URL = `${backendURL}/api/doctorwebdetails/${docid}`;
            }
            const result = await axios.get(URL, config);
            success&&success(result);
            return result;
        } catch (error) {
            if(error ){
                return rejectWithValue(error.message);
            } 
            // else {
            //     return rejectWithValue(error.message);
            // }
        }
    }
);




export const getPatientList = createAsyncThunk(
    'get/patientList',
    async ({ token,practitioner_id,employer_filter,patient_tag,search_key,page,limit,patient_login }, { rejectWithValue }) => {
        try {
            const userInfo = JSON.parse(localStorage.getItem('userInfo')) || {};
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'X-Auth-Token': token
                }
            }
            const payload = {
                "practitioner_id":practitioner_id.toString(),
                 employer_filter:employer_filter,
                 patient_tag:patient_tag,
                 search_key:search_key,
                 page:page,
                 limit:limit,
                 user_type: userInfo?.doctor_login?.user_type,
                 patient_login
            }
            const result = await axios.post(`${backendURL}/api/providerpatientlist`, payload, config);
            return result;

        } catch (error) {
            if(error.response && error.response.data.message){
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);
  
export const PatientAvailableNonAvailableRequest = createAsyncThunk(
    'get/available-non-available-patients',
    async ({practitioner_id,employer_filter,patient_tag,search_key,page,limit }, { rejectWithValue}) => {
        try {
            const payload = {
                "practitioner_id":practitioner_id.toString(),
                 employer_filter:employer_filter,
                 patient_tag:patient_tag,
                 search_key:search_key,
                 page:page,
                 limit:limit
            }
            const result = await GetAvailableNonAvailable(payload);
            return result.data;
        } catch (error) {
            if(error.response && error.response.data.message){
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
)

export const PatientListfilter = createAsyncThunk(
    'patientListfilter',
    async ({ token,practitioner_id }, { rejectWithValue }) => {
        try {
            const userInfo = JSON.parse(localStorage.getItem('userInfo')) || {};
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'X-Auth-Token': token
                }
            }
            // const payload = {
            //     "practitioner_id":practitioner_id.toString()
            // }
            let URL = "";
            if(userInfo && userInfo.doctor_login && userInfo.doctor_login.user_type && userInfo.doctor_login.user_type.length > 0) {
                URL += `${backendURL}/api/patientlistfilter/${practitioner_id}_${userInfo.doctor_login.user_type}`;
            } else {
                URL = `${backendURL}/api/patientlistfilter/${practitioner_id}`;
            }
            const result = await axios.get(URL, config);
            return result;

        } catch (error) {
            if(error.response && error.response.data.message){
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const getPatientDetails = createAsyncThunk(
    'get/PatientDetails',
    async ({ token,patientId }, { rejectWithValue}) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'X-Auth-Token': token
                }
            }
            if(patientId){
            const result = await axios.get(`${backendURL}/api/careweb_patientdetails/${patientId}`, config);
            return result;}
        } catch (error) {
            if(error.response && error.response.data.message){
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
)
export const addPaymentRequest = createAsyncThunk(
    'post/patpay_doctor_request',
    async ({data,success}, { rejectWithValue}) => {
        try {
            const result = await PostPaymentRequest(data);
            success();
            return result;
        } catch (error) {
            if(error.response && error.response.data.message){
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
)
export const addDocumentRequest = createAsyncThunk(
    'post/patdocumentsigning',
    async ({data,success}, { rejectWithValue}) => {
        try {
            const result = await PostDocumentRequest(data);
            success();
            return result;
        } catch (error) {
            if(error.response && error.response.data.message){
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
)
export const downloadDocument = createAsyncThunk(
    'downloadsigningdoc/{doc_id}',
    async ({doc_id,successfull}, { rejectWithValue}) => {
        try {
            const result = await GetDownloadDocument(doc_id);
            successfull(true,doc_id);
            return result;
        } catch (error) {
            if(error.response && error.response.data.message){
                successfull(false);
                return rejectWithValue(error.response.data.message);
            } else {
                successfull(false);
                return rejectWithValue(error.message);
            }
            
        }
    }
)

