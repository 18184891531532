import { createSlice } from "@reduxjs/toolkit";

const SidebarSlice=createSlice({
    name:"sidebar",
    initialState:{open:true},
    reducers:{
        toogle:(state,{payload})=>{
           state.open=!state.open;
        }
    }
})
export const {toogle}=SidebarSlice.actions;
export default SidebarSlice.reducer;