import { createSlice } from "@reduxjs/toolkit";
import { getInfoUser, loginUser, forgetPassword, DoctorDetailsRequest,UpdateDoctorDetailsRequest,UpdateDoctorPasswordRequest,ResetPasswordRequest } from "../actions/authActions";

const initialState = {
    loading: false,
    userInfo:  JSON.parse(localStorage.getItem('userInfo')) || {},
    doctorDetails:{loading:false,error:'',data:[]},
    userToken: JSON.parse(localStorage.getItem('userToken')) || null,
    error: null,
    success: false,
    successMessage:null,
    status:null,
    updateDetails:{loading:false,updateStatus:""},
    updatePassword:{loading:false},
    resetPasswordState:{loading:false}
};

const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        logout: (state) => {
            state.userToken = null;
            state.userInfo = {};    
        },
    },
    extraReducers: {
        [forgetPassword.pending]: (state) => {
            state.loading = true;
            state.error = null;
        },
        [forgetPassword.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.success = true;
            state.successMessage = payload?.data?.message;
            state.status = payload?.data?.result;

        },
        [forgetPassword.rejected]: (state, { payload }) => {
            state.loading = false;
            state.error = payload?.data?.message;
        },
        [loginUser.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [loginUser.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.success = true;
            state.userToken = payload?.data?.acess_token
            state.userInfo = payload?.data?.data
            state.successMessage = payload?.data?.message;
            state.status = payload?.data?.result;
        },
        [loginUser.rejected]: (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        },
        [getInfoUser.pending]: (state) => {
            state.loading = true;
            state.error = null;
        },
        [getInfoUser.fulfilled]: (state, { payload }) => {
            state.userInfo = payload?.data?.user.user;
            state.loading = false
            state.error = null
        },
        [getInfoUser.rejected]: (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        },
        [DoctorDetailsRequest.pending]: (state) => {
            state.doctorDetails.loading = true;
            state.doctorDetails.error = null;
        },
        [DoctorDetailsRequest.fulfilled]: (state, { payload }) => {
            state.doctorDetails.data = payload?.data?.data;
            state.doctorDetails.loading = false
            state.doctorDetails.error = null
        },
        [DoctorDetailsRequest.rejected]: (state, { payload }) => {
            state.doctorDetails.loading = false;
            state.doctorDetails.error = payload;
        }
        ,
        [UpdateDoctorDetailsRequest.pending]: (state) => {
            state.updateDetails.loading = true;
        },
        [UpdateDoctorDetailsRequest.fulfilled]: (state, { payload }) => {
            state.updateDetails.loading = false
            state.updateDetails.updateStatus=payload?.data?.result
        },
        [UpdateDoctorDetailsRequest.rejected]: (state, { payload }) => {
            state.updateDetails.loading = false;
        }
        ,
        [UpdateDoctorPasswordRequest.pending]: (state) => {
            state.updatePassword.loading = true;
        },
        [UpdateDoctorPasswordRequest.fulfilled]: (state) => {
            state.updatePassword.loading = false
        },
        [UpdateDoctorPasswordRequest.rejected]: (state) => {
            state.updatePassword.loading = false;
        },
         [ResetPasswordRequest.pending]: (state) => {
            state.resetPasswordState.loading = true;
        },
        [ResetPasswordRequest.fulfilled]: (state) => {
            state.resetPasswordState.loading = false
        },
        [ResetPasswordRequest.rejected]: (state) => {
            state.resetPasswordState.loading = false;
        }

    },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;
