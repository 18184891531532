import {toast } from 'react-toastify';

export const getToken=()=>{
    if(JSON.parse(localStorage.getItem('userToken')))
    {
      return JSON.parse(localStorage.getItem('userToken'));
    }
    return "";
}
export const toastModal=(message,close,red)=>{
  if (close && !red){
    return toast(message,{autoClose:close})
  }
  if(red && close){
    return toast(message,{autoClose:close,bodyStyle:{color:"red"}})

  }
  else{
    return toast(message);

  }
}
export const toastError=(message)=>{
  return toast.error(message);
}
export const toastInfo=(message)=>{
  return toast.info(message);
}
export const createChannel=async(userId,channelType,sdk, customType = null)=>{
  var sendbird =sdk;
  const params = {};
  params.invitedUserIds=Array.isArray(userId)?[...userId]:[userId];
  params.name=channelType
  params.isDistinct=true
  params.customType = customType
  const channel =  await sendbird.groupChannel.createChannel(params);
  channel.myPushTriggerOption="all"
  return channel
}
export const generateUniqueHandlerId = () => {
  const timestamp = new Date().getTime();
  const randomString = Math.random().toString(36).substring(2, 15);
  return `${timestamp}_${randomString}`;
};
