import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filterData: {},
  ispatientDetail:false
}

const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    getfilterData: (state, { payload }) => {
      state.filterData = payload;
    },
    togglePatientDetail: (state, { payload }) => {
      state.ispatientDetail = payload;
    }
    
  } 
});

export const { getfilterData,togglePatientDetail } = filterSlice.actions;
export default filterSlice.reducer;

