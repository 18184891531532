import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  chatHistory: [],
}

const chatHistorySlice = createSlice({
  name: 'chatHistory',
  initialState,
  reducers: {
    addCall: (state, { payload }) => {
      state.chatHistory.push({
        ...payload,
        status: 'ringing',
        duration: 0,
      })
    },
    updateCall: (state, { payload }) => {
      const index = state.chatHistory.findIndex(
        (call) => call.id === payload.id
      )
      if (index !== -1) {
        state.chatHistory[index] = { ...state.chatHistory[index], ...payload }
      }
    },
    updateCallDuration: (state, { payload }) => {
      const index = state.chatHistory.findIndex(
        (call) => call.id === payload.id
      )
      if (index !== -1) {
        state.chatHistory[index].duration = payload.duration
      }
    },
    deleteAll: (state, { payload }) => {
      state.chatHistory = []
    },
  },
})

export const { addCall, updateCall, updateCallDuration,deleteAll } =
  chatHistorySlice.actions
export default chatHistorySlice.reducer
