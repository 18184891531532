import { Icon } from '@iconify/react'
import React from 'react'

const Archive = () => {
  return (
    <div className='w-full'>
        <div className='w-full flex flex-row justify-between'>
            <h1 className='text-[25px] font-bold text-black'>Archives</h1>
            <div>
                <div className='relative'>
                    <input className='w-[80%] h-[40px] rounded-xl border-[1px] border-[#c4c4c4] focus:border-indigo-500 pl-[40px]' placeholder='Search' />
                    <Icon icon='ic:sharp-search' color='#4361ee' fontSize={24} className='absolute top-2 left-2' />
                </div>
            </div>
        </div>
        <div className='mt-[60px]'>
            <h1 className='text-[80px] text-indigo-500 font-extrabold text-center'>No Archives Yet.</h1>
        </div>
    </div>
  )
}

export default Archive