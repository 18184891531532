/* eslint-disable react-hooks/rules-of-hooks */
import { useState } from "react";
import { BiMicrophone, BiMicrophoneOff } from 'react-icons/bi';
import { MdCallEnd } from 'react-icons/md';
import { BsFillCameraVideoFill, BsFillCameraVideoOffFill } from 'react-icons/bs';
import { useSelector } from "react-redux";

const ChatCall = ({ onCallEnd, onStartVideo, onEndVideo,connected,mute,onMute}) => {
    const [call, setCall] = useState('');
    const [showVideo, SetShowVideo] = useState(true);
    const { open } = useSelector(state => state.sidebar);
    const handleEndCall = () => {
        onCallEnd && onCallEnd();
    }
    const handleVideo = () => {
        if (showVideo) {
            SetShowVideo(false)
            return onEndVideo && onEndVideo();
        }
        else {
            SetShowVideo(true);
            return onStartVideo && onStartVideo();
        }

    }

    return (
        <div className={` ${open?"w-[70%] mr-[320px] ":"w-[80%]"}  `}>

            <section className="w-full h-[65vh] relative p-0 bg-[#F5F5F5]">
                <video id="remote_video_element_id" autoPlay className={`${!connected && 'hidden'} object-contain h-full w-full shadow-sm`}></video>
                <video id="local_video_element_id" autoPlay muted={mute} className={` ${connected ?'w-[300px] right-0 mb-2 mr-2 absolute bottom-0 z-40 shadow-sm':"w-[100%] h-full"}`}></video>
            </section>
            <section className="w-full h-[70px] mt-4 flex justify-center items-center space-x-4 z-40  bottom-[23%]">
                <button onClick={() => onMute()} className="w-[60px] cursor-pointer h-[60px] shadow-md rounded-full bg-gray-300 flex items-center justify-center text-2xl">{mute ? <BiMicrophoneOff /> : <BiMicrophone />}</button>
                <button onClick={handleVideo} className="w-[60px] cursor-pointer h-[60px] shadow-md rounded-full bg-gray-300 flex items-center justify-center text-2xl">{showVideo ? <BsFillCameraVideoFill /> : <BsFillCameraVideoOffFill />}</button>
                <button type="button" onClick={handleEndCall} className="w-[60px] h-[60px] shadow-md rounded-full cursor-pointer bg-red-500 flex items-center justify-center text-2xl text-white"><MdCallEnd /></button>
            </section>
        </div>
    );
}
export default ChatCall;