import React from 'react';

const getAvatarBackground = (fname,lname) => {
  // Function to generate a color based on the first letter of the name
  const colors = [
    'bg-red-500',
    'bg-blue-500',
    'bg-lime-300',
    'bg-orange-400',
    'bg-cyan-500',
    'bg-pink-600',
    'bg-purple-500',
    'bg-teal-500',
  ];

  const firstName = fname.charAt(0).toUpperCase();
  // const lastName = lname.charAt(0).toUpperCase();

  const charCode = firstName.charCodeAt(0);
  const colorIndex = (charCode - 65) % colors.length;
  return colors[colorIndex];
};

const Avatar = ({ fname ,lname, status,image ,showStatus=false,width,profile,onClick=()=>null,className}) => {
  const backgroundClass =fname? getAvatarBackground(fname,lname):'bg-everglade-800';

  return (
    <>
    <div className={`relative inline-block  ${!image?backgroundClass:'bg-transparent'} text-white rounded-full ${width?width:"w-12"}  h-12 flex items-center justify-center`} onClick={onClick}>
      {image && <img className={`rounded-full  ${className} ${profile?"  w-full h-full":"h-12 w-12"} `} src={image}/> }
      {!image && <div className={`${className}`}> <span>{fname?.charAt(0).toUpperCase()}</span><span>{lname?.charAt(0).toUpperCase()}</span></div> }
      {showStatus &&<span className={`absolute bottom-0 right-0 border-2 border-white rounded-full w-4 h-4  ${status==="online"?'bg-everglade-800':'bg-yellow-500'}`}title="Active"/>}
    </div>
      
    </>
  );
};

export default Avatar;
