import { createSlice } from "@reduxjs/toolkit";
import { DeletePreMessageRequest, PostPreMessageRequest, UpdatePreMessageRequest, SaveChannelMessage, saveCallDuration,getChatHistory } from "../actions/chat";


const initialState = {
    add:{loading:false,error:''},
    delete:{loading:false,error:''},
    fetch:{loading:false,data:[],error:""},
    isUploading:false,
    call:{loading:false},
    chatHistory:{loading:false,data:[]}
}

const chatPreSlice = createSlice({
    name: 'chatMessage',
    initialState,
    reducers: {
      toogleUplaod: (state) => {
      state.isUploading = true;;
    }, 
     unToogleUplaod: (state) => {
      state.isUploading = false;
    },  
    },
    extraReducers: {

        [PostPreMessageRequest.pending]: (state) => {
            state.add.loading = true;
            state.error = null;
        },
        [PostPreMessageRequest.fulfilled]: (state, { payload }) => {
            state.add.loading = false;
        },
        [PostPreMessageRequest.rejected]: (state, { payload }) => {
            state.add.loading = false;
            state.add.error = payload;
        },
        [UpdatePreMessageRequest.pending]: (state) => {
            state.add.loading = true;
            state.error = null;
        },
        [UpdatePreMessageRequest.fulfilled]: (state, { payload }) => {
            state.add.loading = false;
        },
        [UpdatePreMessageRequest.rejected]: (state, { payload }) => {
            state.add.loading = false;
            state.add.error = payload;
        },
        [DeletePreMessageRequest.pending]: (state) => {
            state.delete.loading = true;
            state.error = null;
        },
        [DeletePreMessageRequest.fulfilled]: (state, { payload }) => {
            state.delete.loading = false;
        },
        [DeletePreMessageRequest.rejected]: (state, { payload }) => {
            state.delete.loading = false;
            state.delete.error = payload;
        },
        [SaveChannelMessage.pending]: (state) => {
            state.add.loading = true;
            state.error = null;
        },
        [SaveChannelMessage.fulfilled]: (state, { payload }) => {
            state.add.loading = false;
        },
        [SaveChannelMessage.rejected]: (state, { payload }) => {
            state.add.loading = false;
            state.add.error = payload;
        },
        [saveCallDuration.pending]: (state) => {
            state.call.loading = true
        },
        [saveCallDuration.fulfilled]: (state, { payload }) => {
            state.call.loading = false
        },
        [saveCallDuration.rejected]: (state, { payload }) => {
            state.call.loading = false
        },
        [getChatHistory.pending]: (state) => {
            state.chatHistory.loading = true
        },
        [getChatHistory.fulfilled]: (state, { payload }) => {
            state.chatHistory.loading = false
            state.chatHistory.data = payload.data
        },
        [getChatHistory.rejected]: (state, { payload }) => {
            state.chatHistory.loading = false
        },
    }
});

export const { toogleUplaod,unToogleUplaod } = chatPreSlice.actions;

export default chatPreSlice.reducer;
