import useSendbirdStateContext from '@sendbird/uikit-react/useSendbirdStateContext'
import { Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { AiFillCamera } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux'
import CardComponent from '../Components/CardComponent'
import UplaodProfile from '../Components/profileuplaod'
import Avatar from '../Components/shared/chat/avatar'
import { InputFloatingLabel } from '../Components/shared/input'
import Modal from '../Components/shared/modal'
import {
  DoctorDetailsRequest,
  UpdateDoctorDetailsRequest,
} from '../redux/actions/authActions'
import { getdoctorinfo } from '../redux/actions/passActions'
import { toastModal } from '../utils/helpers/methods'

const Profile = () => {
  const { docinfo } = useSelector((state) => state.pass)
  const store = useSendbirdStateContext()
  var sdk = store?.stores.sdkStore.sdk
  const [openModal, setOpenModal] = useState(false)
  const [file, setFile] = useState([])
  const [imageUrl, setImageUrl] = useState('')
  const [firstName, setFirstName] = useState('')
  const [secondName, setSecondName] = useState('')

  const dispatch = useDispatch()

  const { userToken, userInfo, doctorDetails, updateDetails } = useSelector(
    (state) => state.auth
  )

  const initialValues = {
    name: doctorDetails?.data?.name,
    phone_number: doctorDetails?.data?.location?.phone_number,
    address_line1: doctorDetails?.data?.location?.address_line1,
    address_line2: doctorDetails?.data?.location?.address_line2,
    address_city: doctorDetails?.data?.location?.address_state,
    address_state: doctorDetails?.data?.location?.address_city,
    address_zip: doctorDetails?.data?.location?.address_zip,
  }

  useEffect(() => {
    dispatch(getdoctorinfo({ token: userToken, docid: userInfo.id }))
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    dispatch(DoctorDetailsRequest({ id: userInfo?.id }))
    //eslint-disable-next-line
  }, [])

  async function UpdateSendbird(res) {
    const params = {
      nickname: docinfo?.name,
      profileUrl: res?.data?.data?.profile_image,
    }
    const metaData ={
      patient:"false"
    }
    await sdk.updateCurrentUserInfo(params).then((rev) => {
      console.log('updated ....')
    })
    await sdk.currentUser.createMetaData(metaData).then(()=>{
      console.log('updated metadata ok ....')
    }).catch(err=>console.log({err}))
    
  }

  function success() {
    dispatch(
      getdoctorinfo({
        token: userToken,
        docid: userInfo.id,
        success: UpdateSendbird,
      })
    )
    dispatch(DoctorDetailsRequest({ id: userInfo?.id }))
    toastModal('profile Updated succesfully')
  }

  const onSubmit = async (values) => {
    const formData = new FormData()
    formData.append('name', values.name)
    formData.append('profile_img', file)
    formData.append('phone_number', values.phone_number)
    formData.append('address_line1', values.address_line1)
    formData.append('address_line2', values.address_line2)
    formData.append('address_city', values.address_city)
    formData.append('address_state', values.address_state)
    formData.append('address_zip', values.address_zip)
    formData.append(
      'practitioner_id',
      doctorDetails?.data?.doctor_login?.practitioner_id
    )
    dispatch(UpdateDoctorDetailsRequest({ data: formData, success }))
  }

  useEffect(() => {
    if (docinfo) {
      const [firstLetter, secondletter] = docinfo?.name
        ?.split(' ')
        .map((part) => part.charAt(0).toUpperCase())
      setFirstName(firstLetter)
      setSecondName(secondletter)
    }
  }, [docinfo])

  // <div className={`relative inline-block ${!image?backgroundClass:'bg-transparent'} text-white rounded-full ${width?width:"w-12"}  h-12 flex items-center justify-center`}>
  //     {image && <img className='rounded-full w-12 h-12 ' src={image}/> }
  //     {!image && <><span>{fname?.charAt(0).toUpperCase()}</span><span>{lname?.charAt(0).toUpperCase()}</span></> }
  //     {showStatus &&<span className={`absolute bottom-0 right-0 border-2 border-white rounded-full w-4 h-4  ${status==="online"?'bg-everglade-800':'bg-yellow-500'}`}title="Active"/>}
  //   </div>

  return (
    <div className='h-[100%]'>
      <h1 className='text-[25px] font-bold text-everglade-800 p-4'>Profile</h1>
      <div className=' flex flex-row'>
        <div className='p-3 w-[40%]  '>
          <div className="flex h-100">
            <div className="w-100 rounded-lg shadow-lg overflow-hidden flex flex-col">
              <div className="w-full h-64 bg-gradient-to-b to-everglade-200 from-everglade-400 flex justify-center items-center">

                {!doctorDetails?.data?.profile_image ? (
                  <Avatar
                    fname={firstName}
                    lname={secondName}
                    width={'w-[200px] h-[200px] text-2xl '}
                  />

                ) : (
                  <Avatar
                    image={doctorDetails?.data?.profile_image}
                    width={'w-[200px] h-[200px] text-2xl '}
                    profile={true}
                  />
                )}

              </div>
              <div className="flex flex-col  p-2  justify-center items-center" >
                <div className="grid grid-cols-1">
                  <h1 className='text-everglade-950 font-bold tracking-wide text-xl capitalize'>
                    {docinfo?.name}
                  </h1>
                </div>
              </div>
              <div className="flex flex-col justify-between p-4">
                <div className="grid grid-cols-2 gap-4">
                  <div className="font-bold text-everglade-900 p-1">
                    {/* bg-gray-200 rounded-lg */}
                    Practitioner ID
                  </div>
                  <div className=" p-1">
                    {docinfo?.id}
                  </div>
                  <div className="font-bold text-everglade-900 p-1">
                    Email ID
                  </div>
                  <div className="p-1 break-all">
                    {docinfo?.email_address}
                  </div>
                  <div className="font-bold text-everglade-900 p-1">
                    Gender
                  </div>
                  <div className=" p-1">
                    {docinfo?.gender}
                  </div>
                  <div className="font-bold text-everglade-900 p-1">
                    Phone
                  </div>
                  <div className=" p-1">
                    {docinfo?.location?.phone_number}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <CardComponent
            contain={true}
            width={true}
            className='bg-gradient-to-b from-everglade-200 to-everglade-400'
          >
            <div className='flex'>
              <div className='flex-1'>
                {!doctorDetails?.data?.profile_image ? (
                  <Avatar
                    fname={firstName}
                    lname={secondName}
                    width={'w-[200px] h-[200px] text-2xl '}
                  />
                ) : (
                  <Avatar
                    image={doctorDetails?.data?.profile_image}
                    width={'w-[200px] h-[200px] text-2xl '}
                    profile={true}
                  />
                )}
              </div>
              <div className='flex-1'>
                <h1 className='text-everglade-950 font-bold tracking-wide text-xl capitalize pb-5'>
                  {docinfo?.name}
                </h1>
                <p>
                  <span className='font-bold text-everglade-900'>
                    Practitioner ID :{' '}
                  </span>
                  <span>{docinfo?.location?.practitioner_id}</span>
                </p>
                <p>
                  <span className='font-bold text-everglade-900'>Email :</span>
                  <span>{docinfo?.email_address}</span>
                </p>
                <p>
                  <span className='font-bold text-everglade-900'>
                    Gender :{' '}
                  </span>
                  <span>{docinfo?.gender}</span>
                </p>
                <p>
                  <span className='font-bold text-everglade-900'>Phone : </span>
                  <span>{docinfo?.location?.phone_number}</span>
                </p>
              </div>
            </div>
          </CardComponent> */}
        </div>
        <div className='p-3 w-[100%]'>
          <div className='border p-5  rounded-md shadow-2xl'>
            <div className='mb-5 relative '>
              {!imageUrl && !doctorDetails?.data?.profile_image ? (
                <Avatar
                  fname={firstName}
                  lname={secondName}
                  width={'w-[200px] h-[200px] text-2xl cursor-pointer  '}
                  onClick={() => setOpenModal(true)}
                />
              ) : (
                <Avatar
                  image={imageUrl || doctorDetails?.data?.profile_image}
                  width={'w-[200px] h-[200px] text-2xl cursor-pointer  '}
                  profile={true}
                  onClick={() => setOpenModal(true)}
                />
              )}
              <AiFillCamera
                size={30}
                color='#2c532a'
                className=' absolute bottom-0  left-[130px] cursor-pointer '
                onClick={() => setOpenModal(true)}
              />
              <div></div>
            </div>
            {openModal && (
              <Modal title={"Upload profile picture"} close={() => setOpenModal(false)}>
                <UplaodProfile
                  close={() => setOpenModal(false)}
                  setFile={setFile}
                  setImageUrl={setImageUrl}
                />
              </Modal>
            )}
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              onSubmit={onSubmit}
            >
              <Form className='mt-10'>
                <div className='grid grid-cols-2 gap-4 m-5'>
                  {/* <InputText
                    name='name'
                    label='Name'
                    color={true}
                    placeholder='name'
                    border={true}
                  /> */}
                  <InputFloatingLabel name='name' label='Name' />
                  {/* <InputText
                    name='phone_number'
                    label='Phone number'
                    color={true}
                    placeholder='phone number'
                    border={true}
                  /> */}
                  <InputFloatingLabel
                    name='phone_number'
                    label='Phone number'
                  />
                </div>
                <div className='grid grid-cols-2 gap-4 m-5'>
                  {/* <InputText
                    name='address_line1'
                    label='Address line 1'
                    color={true}
                    placeholder='address line 1'
                    border={true}
                  /> */}
                  <InputFloatingLabel
                    name='address_line1'
                    label='Address line 1'
                  />
                  <InputFloatingLabel
                    name='address_line2'
                    label='Address line 2'
                  />

                  {/* <InputText
                    name='address_line2'
                    label='Address line 2'
                    color={true}
                    placeholder='address line'
                    border={true}
                  /> */}
                </div>
                <div className='grid grid-cols-2 gap-4 m-5'>
                  {/* <InputText
                    name='address_state'
                    label='Address state'
                    color={true}
                    placeholder='address state'
                    border={true}
                  /> */}
                  <InputFloatingLabel
                    name='address_state'
                    label='Address state'
                  />
                  <InputFloatingLabel
                    name='address_city'
                    label='Address city'
                  />

                  {/* <InputText
                    name='address_city'
                    label='Address city'
                    color={true}
                    placeholder='address city'
                    border={true}
                  /> */}
                </div>
                <div className='grid grid-cols-2 gap-4 m-5'>
                  {/* <InputText
                    name='address_zip'
                    label='Zip code'
                    color={true}
                    placeholder='zip code'
                    border={true}
                  /> */}
                  <InputFloatingLabel name='address_zip' label='Zip code' />
                </div>

                <button
                  type='submit'
                  className='lg:flex border-2   mt-3.5 w-[153PX] h-14 lg:mt-12 rounded border-everglade-700 bg-everglade-700 hover:border-everglade-400 hover:bg-everglade-400   uppercase flex items-center justify-center text-sm poppins font-medium text-white'
                >
                  {updateDetails?.loading ? 'Loading..' : 'Submit'}
                </button>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Profile
