import { Form, Formik } from 'formik'
import { InputFile, InputFloatingLabel } from '../shared/input'
import { useState } from 'react'
import { addDocSchema } from './schema'
import { useDispatch, useSelector } from 'react-redux'
import {
  addDocumentRequest,
  getPatientDetails,
} from '../../redux/actions/passActions'
import Spinner from '../shared/spinner'
import { toastModal } from '../../utils/helpers/methods'
import Dropzone from 'react-dropzone'

const AddDocumentRequest = ({ patientId, close }) => {
  const { userToken, userInfo } = useSelector((state) => state.auth)
  const { addDocumentRequest: docRequestState } = useSelector(
    (state) => state.patient
  )
  const [file, setFile] = useState('')
  const [fileName, setFileName] = useState('')

  const dispatch = useDispatch()
  const initialValues = {
    title: '',
    file: '',
  }
  const [files, setFiles] = useState([])
  const onDrop = (File) => {
    if (File.length > 0) {
      setFileName(File[0].name)
      setFiles(File[0])
    }
  }
  const handleSuccessfull = () => {
    toastModal('Successfull Done')
    setTimeout(() => {
      dispatch(getPatientDetails({ token: userToken, patientId: patientId }))
      close()
    }, 100)
  }
  const [isDragging, setIsDragging] = useState(false)

  const handleSubmit = (values) => {
    const formData = new FormData()
    formData.append('document_title', values.title)
    formData.append('file', files)
    formData.append('form_type', 'care_app')
    if(userInfo && userInfo.doctor_login && userInfo.doctor_login.user_type && userInfo.doctor_login.user_type === "nonprovider") {
      formData.append('requester_type', userInfo.doctor_login.user_type);
    } else{
      formData.append('requester_type','doctor');
    }
    formData.append('request_from', userInfo.name)
    formData.append('requester_id', userInfo.id)
    formData.append('patient_id', patientId)
    dispatch(addDocumentRequest({ data: formData, success: handleSuccessfull }))
  }
  const handleFileChange = (e) => {
    setFile(e.target.files[0])
  }
  return (
    <>
      <div className='w-full h-min p-2 mt-2'>
        
        <section className='px-2'>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={addDocSchema}
          >
            <Form>
              <section className='flex flex-col w-full p-1'>
                <InputFloatingLabel name='title' label='Title' />
                {/* <InputFile name="file" accept=".doc,.docx,.pdf,.txt,.csv,.jpg,.jpeg,.png,.svg" label="File" handleChange={handleFileChange}/> */}
                <h3>Upload file</h3>
                <Dropzone
                  multiple={false}
                  onDrop={onDrop}
                  onDragEnter={() => setIsDragging(true)}
                  onDragLeave={() => setIsDragging(false)}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section
                      className={` relative border-dashed border w-full h-[80px]  mt-2 mb-2 ${
                        isDragging ? ' border-everglade-600' : 'border-gray-400'
                      }`}
                    >
                      <div
                        {...getRootProps({
                          className:
                            'text-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full',
                        })}
                      >
                        <input {...getInputProps()} />
                        {fileName.length > 0 ? (
                          <div className=' font-bold'>{fileName}</div>
                        ) : (
                          <div div className='font-bold'>
                            <p>
                              Drag and drop file here, or click to select file
                            </p>
                          </div>
                        )}
                      </div>
                    </section>
                  )}
                </Dropzone>
              </section>
              <section className='flex justify-center items-center'>
                <button
                  type='submit'
                  disabled={docRequestState.loading||files.length===0}
                  className='lg:flex border-2   w-[153PX] h-14 lg:mt-6 rounded border-everglade-700 bg-everglade-700 hover:bg-everglade-400 hover:border-everglade-400  uppercase flex items-center justify-center text-sm poppins font-medium text-white'
                >
                  {docRequestState.loading ? <Spinner /> : 'Submit'}
                </button>
              </section>
            </Form>
          </Formik>
        </section>
      </div>
    </>
  )
}
export default AddDocumentRequest
