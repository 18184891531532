import { createSlice } from "@reduxjs/toolkit";
import {addPaymentRequest, addDocumentRequest, downloadDocument, PatientAvailableNonAvailableRequest } from "../actions/passActions";

const initialState = {
    loading: false,
    addPaymentRequest: { loading: false, error: "" },
    addDocumentRequest: { loading: false, error: "" },
    downloadDocument: { loading: false, error: "" },
    getAvailableNonAvailable: { loading: false, error: "",data:[] },
    error: null,
    successMessage: null
}

const patientSlice = createSlice({
    name: 'patient',
    initialState,
    reducers: {},
    extraReducers: {

        [downloadDocument.pending]: (state) => {
            state.downloadDocument.loading = true;
            state.error = null;
        },
        [downloadDocument.fulfilled]: (state, { payload }) => {
            state.downloadDocument.loading = false;
        },
        [downloadDocument.rejected]: (state, { payload }) => {
            state.downloadDocument.loading = false;
            state.downloadDocument.error = payload;
        },
        [addDocumentRequest.pending]: (state) => {
            state.addDocumentRequest.loading = true;
            state.error = null;
        },
        [addDocumentRequest.fulfilled]: (state, { payload }) => {
            state.addDocumentRequest.loading = false;
        },
        [addDocumentRequest.rejected]: (state, { payload }) => {
            state.addDocumentRequest.loading = false;
            state.addDocumentRequest.error = payload;
        },
        [addPaymentRequest.pending]: (state) => {
            state.addPaymentRequest.loading = true;
            state.error = null;
        },
        [addPaymentRequest.fulfilled]: (state, { payload }) => {
            state.addPaymentRequest.loading = false;
        },
        [addPaymentRequest.rejected]: (state, { payload }) => {
            state.addPaymentRequest.loading = false;
            state.addPaymentRequest.error = payload;
        },
        [PatientAvailableNonAvailableRequest.pending]: (state) => {
            state.getAvailableNonAvailable.loading = true;
            state.error = null;
        },
        [PatientAvailableNonAvailableRequest.fulfilled]: (state, { payload }) => {
            state.getAvailableNonAvailable.loading = false;
            state.getAvailableNonAvailable.data = payload?.data;

        },
        [PatientAvailableNonAvailableRequest.rejected]: (state, { payload }) => {
            state.getAvailableNonAvailable.loading = false;
            state.getAvailableNonAvailable.error = payload;
        },


    }
});

export default patientSlice.reducer;
