export const  PurposeData = [
    { label: 'Copay-MRI', value: 'Copay-MRI' },
    { label: 'Copay-CT', value: 'Copay-CT' },
    { label: 'Copay-SLEEP STUDY', value: 'Copay-SLEEP STUDY' },
    { label: 'Copay-DME', value: 'Copay-DME' },
    { label: 'Service Fee-US', value: 'Service Fee-US' },
    { label: 'Service Fee-CT', value: 'Service Fee-CT' },
    { label: 'Service Fee-MRI', value: 'Service Fee-MRI' },
    { label: 'Service Fee-DME', value: 'Service Fee-DME' },
    { label: 'Service Fee-Labs', value: 'Service Fee-Labs' },
    { label: 'Service Fee', value: 'Service Fee' },
    { label: 'Copay', value: 'Copay' },
    { label: 'Others', value: 'Others' },
  
  ];
  export const RequsterData= [
    { label: 'Physician', value: 'Physician' },
    { label: 'MyMd Connect', value: 'MyMd Connect' },
    { label: 'Dev-test', value: 'Dev-test' },
  
  ];
  
  // src/Calls/settings.js
export const callStates = {
  ringing: {
      info: "Ringing... Pick up!",
        displayPickup: true,
        displayCall: false,
        displayCallEnd: false
  },
  established : {
      info: "Call established",
      displayPickup: false,
      displayCall: false,
      displayCallEnd: true
    },
  connected: {
      info: "Call connected",
      displayPickup: false,
      displayCall: false,
      displayEnd: true
    },
  ended: {
      info: "Call ended",
      displayPickup: false,
      displayEnd: false,
      displayCall: true
  }
}