import { Icon } from '@iconify/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import IconMD from "../images/icon.png";
import DoctorImage from "../images/undrawMedicine.svg";
import { loginUser } from '../redux/actions/authActions';
const Login = () => {
    const { loading, error, status, successMessage } = useSelector((state) =>state.auth );
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [data, setData] = useState({
        email: '',
        password: '',
        isPasswordVisible: false,
        credentialsInvalid: false,
        isloginfailed: false,
    });

    const [errors,setError]=useState(false)
    const [clicked,setClicked]=useState(false)

    useEffect(() => {
        if (error && error != 'Network Error') {
            setData({ ...data, credentialsInvalid: true })
            setTimeout(() => {
                setData({ ...data, credentialsInvalid: false })
            }, 4000);
        };
        //eslint-disable-next-line
    }, [error])


    useEffect(()=>{
        if(!loading){
            if (status == 'failed') {
                setData({ ...data, isloginfailed: true })
                setTimeout(() => {
                    setData({ ...data, isloginfailed: false })
                }, 4000);
            }
        }
        //eslint-disable-next-line
    },[status,loading])


    useEffect(()=>{
        return ()=>setClicked(false)
    },[])


    const handleLogin = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
    if (!data.email || !data.password || !emailRegex.test(data.email)) {
        setError(true);
        setTimeout(() => {
            setError(false);
        }, 4000);
    }
        else{
            setClicked(true)
            const payload = {
                email: data.email,
                password: data.password,
                callBack
            }
            dispatch(loginUser(payload));
        }
        
        
    }

    function callBack(){
       navigate('/dashboard')
    }
    return (
        <div className='h-screen  grid grid-cols-1 lg:grid-cols-2 w-full'>
            <section className='h-screen bg-everglade-300 hidden md:flex justify-center'>
                <img src={DoctorImage} alt='doctor' />
            </section>
            <section className='min-h relative'>
            <div className='flex justify-center items-center  absolute   top-20 right-0 left-0'>
             <section className=' pb-4  mt-10 lg:mt-1'><img src={IconMD} className='w-[100%]' alt='mymd'/></section>
                        </div>
                <section className='items-center flex relative px-10 flex-col h-screen justify-center'>
                    <div className='w-full md:w-4/5 pl-5 flex flex-col justify-center w-full'>
                        
                        <div className='flex flex-row gap-2 items-center'>
                        <Icon icon='fluent-emoji-high-contrast:japanese-passing-grade-button' color='#4f9d4a' fontSize={30} className='font-extrabold' />
                            <h1 className='font-bold tracking-[2px] text-orange-900 text-[23px]'><span className=' text-everglade-600 font-bold'>Care</span>Web</h1>
                        </div>
                        {/* <h1 className='text-[40px] ml-10 font-bold text-slate-800'>Care</h1> */}
                        <h2 className='text-[#4c4c4c] ml-10'>Login to your Account</h2>
                    </div>
                    <section className='h-[400px] w-full md:w-4/5 mt-10 md:px-5 space-y-10 flex flex-col items-center justify-center'>
                        <div className='w-full relative'>
                            <input value={data.email} onChange={(e) => setData({ ...data, email: e.target.value })} type='email' className='w-full h-[60px] border-[1px] border-[#c4c4c4] rounded-xl pl-[50px] focus:border-everglade-500 focus:ring-1 focus:ring-everglade-500' placeholder='Email' />
                            <Icon icon='ic:baseline-mail-outline' color='#4f9d4a' fontSize={35} className='absolute top-3 left-2' />
                        </div>
                        <div className='w-full relative'>
                            <input value={data.password} onChange={(e) => setData({ ...data, password: e.target.value })} type={data.isPasswordVisible ? 'text' : 'password'} className='w-full h-[60px] border-[1px] border-[#c4c4c4] rounded-xl pl-[50px] focus:border-everglade-500 focus:ring-1 focus:ring-everglade-500' placeholder='Password' />
                            <Icon icon='material-symbols:lock-outline' color='#4f9d4a' fontSize={35} className='absolute top-3 left-2' />
                            <Icon onClick={() => setData({ ...data, isPasswordVisible: !data.isPasswordVisible })} icon={data.isPasswordVisible ? 'mdi:eye-outline' : 'mdi:eye-off-outline'} color='#4f9d4a' fontSize={35} className='absolute top-3 right-[20px] cursor-pointer' />
                            {(data.isloginfailed &&!loading )&&status==="failed"&& clicked&& <p className='mt-5 text-red-600'>{successMessage}</p>}
                        </div>
                        <div className=' text-left' >
                        <p className='text-red-600 '>{errors&&"Please enter a valid email and password"}</p>

                        </div>
                        <div className='w-full p-2'>
                            <h1 className='w-full flex justify-end pr-2'>Forgot &nbsp;<Link to='/forgetpassword' className='text-everglade-600 font-bold'>password ?</Link></h1>
                        </div>
                        <div className='w-full'>
                            <button onClick={handleLogin}  className='w-[100%] border h-[60px] bg-everglade-600 hover:bg-everglade-400 text-white rounded-xl'>{loading ? 'Loading...' : 'Login'}</button>
                        </div>
                    </section>  
                </section>
            </section>
        </div>
    )
}

export default Login
