import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer'
import sendbirdSelectors from '@sendbird/uikit-react/sendbirdSelectors'
import useSendbirdStateContext from '@sendbird/uikit-react/useSendbirdStateContext'
import { useState } from 'react'
import { AiOutlineSend } from 'react-icons/ai'

export const DisplayProfile = ({
  imageUrl,
  fileName,
  file,
  channel,
  onClose,
}) => {
  const store = useSendbirdStateContext()
  const sendFileMessage = sendbirdSelectors?.getSendFileMessage(store)
  const [isSending, setIsSending] = useState(false)

  const SendFile = () => {
    onClose()
    setIsSending(true)
    sendFileMessage(channel, {
      file,
    })
      .onSucceeded(() => {
        setIsSending(false)
        onClose()
      })
      .onFailed((err) => {
        setIsSending(false)
        console.log({ err })
      })
  }

  return (
    <div className='p-10'>
      <DocViewer
        documents={[
          {
            uri: imageUrl,
            fileName: fileName,
          },
        ]}
        pluginRenderers={DocViewerRenderers}
        className='w-[800px] h-[100%] text-2xl cursor-pointer '
      />
      <div className='flex justify-end mt-5'>
        <button
          type='button'
          className='p-5  h-[35px] text-white  bg-everglade-800 hover:bg-everglade-400 rounded-md flex items-center justify-center'
          onClick={SendFile}
          disabled={isSending}
        >
          <AiOutlineSend size={25} />
        </button>
      </div>
    </div>
  )
}
